import { faCircleDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome"
import React, { Dispatch, SetStateAction, useState } from "react"
import { Card, Table } from "react-bootstrap"
import Moment from "react-moment"
import Viewer from "react-viewer"
import { CelebMedicalFileService } from "../../../services/CelebMedicalFile.Service"
import { getTreatmentFilesByCategoryQuery } from "../../../services/CelebTreatmentFiles.Service"
import { useDoctorListQuery } from "../../../services/CelebUser.Service"
import { usePatientDialogStore } from "../../../store/patientDailogStore"
import { useSidebarStore } from "../../../store/sideBarStore"
import {
	sidebarPhotosLibrary as photosLibrary,
	sidebarPhotosLibraryReset,
} from "../../../utils/SidebarphotoFileShowCase"
import { downloadFile } from "../../../utils/utilFunctions"
import { fileResult } from "../../dialogs/replaceOrder-v2/PatientPhotos.tab"
import { CheckGenericPermission } from "../../permissions/CheckPermision"
import { fileReadableName } from "../../utlity/GenricFunctions"
import "./sidebar.css"

const Records = () => {
	const sideBarData = useSidebarStore()
	const { doctorList } = usePatientDialogStore()
	const [ images, setImages ] = React.useState<ImageDetailsType[]>( [] )
	const [ miscImages, setMiscImages ] = React.useState<ImageDetailsType[]>( [] )
	const [ phLibrary, setPhLibrary ] = React.useState<{
		[key: string]: any
	}>( [] )

	const [ compositeImages, setCompositeImages ] = React.useState<ImageDetailsType[]>( [] )
	const [ visible, setVisible ] = useState( {
		show: false,
		key: 0,
		photos: "",
	} )

	const entityId = sideBarData?.sideBarPatientData?.PatientData?.patient?.entityId ?? ""
	const isReplacementOrder = sideBarData?.sideBarPatientData?.PatientData?.isReplacementOrder
	const { data: fileData } = getTreatmentFilesByCategoryQuery(
		sideBarData?.sideBarPatientData?.PatientData?.id,
		!isReplacementOrder ? [ "patient" ] : [ "replace" ],
	)
	useDoctorListQuery()

	React.useEffect( () => {
		sidebarPhotosLibraryReset()
		setData()
	}, [ fileData ] )

	const setData = async () => {
		const data = await processFiles( fileData )
		console.log( data )
		setPhLibrary( data.photosLibrary )
		setCompositeImages( data.compositeImages )
		setImages( data.images )
		setMiscImages( data.miscImages )
	}

	const doctorNameById = ( id: string = "" ) => {
		const doctor = doctorList.find( ( doctors ) => doctors.value === id )
		return doctor ? "Dr. " + doctor.label : ""
	}

	return (
		<main
			style={{
				backgroundColor: "#d9d9d9",
				width: "-webkit-fill-available",
				borderRadius: "10px",
				overflow: "scroll",
				scrollbarWidth: "none",
			}}
			className="p-3"
		>
			{phLibrary && compositeImages.length == 0 && !isReplacementOrder && images.length >= 1 && (
				<>
					<CheckGenericPermission genericLabel="Treatment Photo Individual">
						<div>
							<Table variant="secondary" responsive className="mt-2">
								<tbody>
									<center>
										<tr className="border-0">
											<td>
												<img
													src={phLibrary[ "patient_photo_face_side" ]}
													className="imgGridPlace "
													onClick={() =>
														images[ 0 ]
															? setVisible( { show: true, key: 0, photos: "individual" } )
															: ""
													}
												/>
											</td>
											<td>
												<img
													src={
														phLibrary[ "patient_photo_face_front" ] &&
														phLibrary[ "patient_photo_face_front" ]
													}
													className="imgGridPlace "
													onClick={() =>
														images[ 1 ]
															? setVisible( { show: true, key: 1, photos: "individual" } )
															: ""
													}
												/>
											</td>
											<td>
												<img
													src={
														phLibrary[ "patient_photo_face_front_teeth" ] &&
														phLibrary[ "patient_photo_face_front_teeth" ]
													}
													className="imgGridPlace "
													onClick={() =>
														images[ 2 ]
															? setVisible( { show: true, key: 2, photos: "individual" } )
															: ""
													}
												/>
											</td>
										</tr>
										<tr className="border-0">
											<td>
												<img
													src={phLibrary[ "patient_photo_jaw_upper" ]}
													className="imgGridPlace "
													onClick={() =>
														images[ 3 ]
															? setVisible( { show: true, key: 3, photos: "individual" } )
															: ""
													}
												/>
											</td>
											<td>
												<img src={phLibrary[ "" ]} className="imgGridPlace " />
											</td>
											<td>
												<img
													src={phLibrary[ "patient_photo_jaw_lower" ]}
													className="imgGridPlace "
													onClick={() =>
														images[ 4 ]
															? setVisible( { show: true, key: 4, photos: "individual" } )
															: ""
													}
												/>
											</td>
										</tr>
										<tr className="border-0">
											<td>
												<img
													src={phLibrary[ "patient_photo_jaw_right" ]}
													className="imgGridPlace "
													onClick={() =>
														images[ 5 ]
															? setVisible( { show: true, key: 5, photos: "individual" } )
															: ""
													}
												/>
											</td>
											<td>
												<img
													src={phLibrary[ "patient_photo_jaw_front" ]}
													className="imgGridPlace "
													onClick={() =>
														images[ 6 ]
															? setVisible( { show: true, key: 6, photos: "individual" } )
															: ""
													}
												/>
											</td>
											<td>
												<img
													src={phLibrary[ "patient_photo_jaw_left" ]}
													className="imgGridPlace "
													onClick={() =>
														images[ 7 ]
															? setVisible( { show: true, key: 7, photos: "individual" } )
															: ""
													}
												/>
											</td>
											<td></td>
										</tr>
									</center>
								</tbody>
							</Table>
						</div>
					</CheckGenericPermission>
				</>
			)}

			<Viewer
				visible={visible.show}
				onClose={() => {
					setVisible( { show: false, key: 0, photos: "" } )
				}}
				images={[ ...images, ...compositeImages, ...miscImages ]}
				downloadable
				activeIndex={visible.key}
				showTotal
			/>

			{miscImages && (
				<ShowPicture
					doctorNameById={doctorNameById}
					images={[ ...compositeImages, ...miscImages ]}
					isReplacementOrder={isReplacementOrder}
					setVisible={setVisible}
					imgCount={images.length}
				/>
			)}

			<hr />
			<text className="sidebar-subject">Files:</text>
			<br />
			<div>
				{fileData.map( ( file: fileResult, index: number ) => {
					if ( !file.fileCategory.includes( "photo" ) && !file.fileCategory.includes( "xray" ) ) {
						return (
							<CheckGenericPermission genericLabel="Treatment Files">
								<div
									className="file-item mb-2 p-2 border rounded"
									key={index}
									onClick={async () => {
										sideBarData.addDownloadingFile( index )
										const result = await downloadFile( file.fileUri, file.originalFileName, entityId )
										if ( result ) {
											sideBarData.removeDownloadingFile( index )
										}
									}}
								>
									<span className="icon-container">
										{sideBarData.downloadingFileIndexes.includes( index ) ? (
											<div className="spinner-border text-secondary" role="status">
												<span className="sr-only">Loading...</span>
											</div>
										) : (
											<FA icon={faCircleDown} style={{ fontSize: "35px", color: "#8faadc" }} />
										)}
									</span>

									<span className="flex-grow-1">
										<div className="file-name">{fileReadableName( file.fileSubCategory )}</div>
										<div className="doctor-name">{doctorNameById( file.doctorId )}</div>
									</span>
									<span className="date-container text-end">
										<div className="date-text">
											<Moment format="MM/DD/YYYY hh:mma" local>
												{file.updatedAt}
											</Moment>
										</div>
									</span>
								</div>
							</CheckGenericPermission>
						)
					}
				} )}
			</div>
		</main>
	)
}

export type ImageDetailsType = {
	src: string
	alt?: string
	uploadedBy?: string
	updatedAt?: string
}

export default Records

const ShowPicture = ( {
	images,
	setVisible,
	doctorNameById,
	imgCount,
}: {
	images: ImageDetailsType[]
	isReplacementOrder: boolean
	setVisible: Dispatch<SetStateAction<{ show: boolean; key: number; photos: string }>>
	doctorNameById: ( id?: string ) => string
	imgCount: number
} ) => {
	return (
		<>
			<div style={{ backgroundColor: "#d9d9d9" }}>
				{images?.map( ( image, index ) => (
					<Card key={index}>
						<div style={{ position: "relative" }}>
							<img
								src={image.src}
								style={{ height: "100%", width: "100%", borderRadius: "5px", padding: "2px" }}
								onClick={() =>
									setVisible( { show: true, key: index + imgCount, photos: "composite" } )
								}
							/>
							<Card.Footer
								style={{
									backgroundColor: "white",
									display: "flex",
									justifyContent: "space-between",
									whiteSpace: "wrap",
									padding: "6px 20px",
								}}
							>
								<div>{image.uploadedBy && doctorNameById( image?.uploadedBy )}</div>
								<div>
									<Moment format="MM/DD/YYYY hh:mma" local>
										{image.updatedAt}
									</Moment>
								</div>
							</Card.Footer>
						</div>
					</Card>
				) )}
			</div>
		</>
	)
}
// Helper function to fetch the image URL
const fetchImageUri = async ( fileUri: string ) => {
	try {
		const response = await CelebMedicalFileService.getInstance().getFileReadUrl( fileUri )
		return response.data.data
	} catch ( error ) {
		console.error( "Error fetching image URI:", error )
		return null // Return null in case of error
	}
}

const processFiles = async ( fileData: fileResult[] ) => {
	const images: ImageDetailsType[] = []
	const compositeImages: ImageDetailsType[] = []
	const miscImages: ImageDetailsType[] = []
	await Promise.all(
		fileData.map( async ( file: fileResult ) => {
			const imageUri = await fetchImageUri( file.fileUri )

			if ( !imageUri ) return file

			switch ( true ) {
				case file.fileCategory == "patient_photo" &&
					file.fileSubCategory != "patient_photo_composite":
					photosLibrary[ file.fileSubCategory ] = imageUri
					images.push( {
						src: imageUri,
						alt: "Photos",
						uploadedBy: file.doctorId,
						updatedAt: file.updatedAt,
					} )
					break

				case file.fileSubCategory.includes( "patient_photo_composite" ):
					compositeImages.push( {
						src: imageUri,
						alt: "Composite Photo",
						uploadedBy: file.doctorId,
						updatedAt: file.updatedAt,
					} )
					break
				case file.fileCategory.includes( "patient_xray" ):
					miscImages.push( {
						src: imageUri,
						alt: file.fileSubCategory || "X-ray",
						uploadedBy: file.doctorId,
						updatedAt: file.updatedAt,
					} )
					break
				case file.fileSubCategory.includes( "replace_order_photo" ):
					miscImages.push( {
						src: imageUri,
						alt: "Replacement Photo",
						uploadedBy: file.doctorId,
						updatedAt: file.updatedAt,
					} )
					break
			}
			return file
		} ),
	)

	return { photosLibrary, images, compositeImages, miscImages }
}
