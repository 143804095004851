import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Button, CloseButton, Col, Form, Row } from "react-bootstrap"
import fileReguler from "../../../assets/img/fileRegular.svg"
import { getFileReadUrlQuery } from "../../../services/CelebMedicalFile.Service"
import { getPatientTreatmentFilesQuery } from "../../../services/CelebTreatmentFiles.Service"
import { PatientDialogState, usePatientDialogStore } from "../../../store/patientDailogStore"
import { photosLibrary } from "../editPatients-v2/defaultFileShowCase"
import { fileResult } from "./PatientPhotos.tab"

const PatientScanTab = () => {
	return (
		<React.Suspense>
			<PatientScanTabImpl />
		</React.Suspense>
	)
}

const PatientScanTabImpl = () => {
	const [ selectedTab, setSelectedTab ] = React.useState<number>( 0 )

	return (
		<React.Fragment>
			<Col className="d-flex p-2 gap-5">
				<Form.Check
					type="radio"
					reverse
					label="Upload STL files"
					name="scanType"
					value={0}
					defaultChecked
					onChange={() => setSelectedTab( 0 )}
				/>
				<Form.Check
					type="radio"
					reverse
					label="Upload from scanner"
					name="scanType"
					value={1}
					onChange={() => setSelectedTab( 1 )}
				/>
			</Col>
			{selectedTabCaseHandler( selectedTab )}
			<footer className="d-flex m-3 position-absolute bottom-0 end-0 gap-2">
				<Button className="me-2 mb-2" onClick={( e ) => ( window.location.hash = "#PatientInfo" )}>
					<FontAwesomeIcon
						icon={faChevronLeft}
						style={{ objectFit: "contain", width: "1rem", height: "1rem" }}
						className="m-0 p-0"
					/>
				</Button>
				<Button className="me-2 mb-2" onClick={( e ) => ( window.location.hash = "#Replace" )}>
					<FontAwesomeIcon
						icon={faChevronRight}
						style={{ objectFit: "contain", width: "1rem", height: "1rem" }}
						className="m-0 p-0"
					/>
				</Button>
			</footer>
		</React.Fragment>
	)
}

const selectedTabCaseHandler = ( tabId: number ) => {
	switch ( tabId ) {
		case 0:
			return <UploadStlFiles />
		case 1:
			return <UploadFromScanner />
		default:
			return <UploadStlFiles />
	}
}

export const UploadStlFiles = () => {
	const { setScan, scan, replaceOpen } = usePatientDialogStore()
	const { data: defaultTreatmentFiles } = getPatientTreatmentFilesQuery( replaceOpen.id )

	defaultTreatmentFiles.forEach( ( file: fileResult ) => {
		if ( file.fileCategory == "patient_scan" ) {
			const imageUri = getFileReadUrlQuery( file.fileUri )
			photosLibrary[ file.fileSubCategory ] = imageUri.data
		}
	} )

	const fileImageSaver = (
		e: React.ChangeEvent<HTMLInputElement>,
		property: keyof PatientDialogState["scan"],
	) => {
		e.preventDefault()
		if ( e.currentTarget.files ) {
			setScan( property, {
				additionalInfo: {},
				file: e.currentTarget.files[ 0 ],
			} )
		}
	}
	return (
		<React.Fragment>
			<Form>
				<Row className="my-4">
					<Col>
						<div
							className="d-flex gap-3"
							style={{
								alignItems: "center",
								padding: "8px",
								border: "1px solid #ced4da",
								borderRadius: "6px",
								backgroundColor: "#f9f9f9",
							}}
						>
							{" "}
							<label
								style={{
									cursor: "pointer",
									alignItems: "center",
									gap: "8px",
								}}
								htmlFor="file-input-upper"
								className="d-flex"
							>
								<img
									src={
										scan.patient_scan_upper.file
											? URL.createObjectURL( scan.patient_scan_upper.file )
											: photosLibrary[ "patient_scan_upper" ]
									}
									style={{
										objectFit: "cover",
									}}
									className="upload-file-icon"
									onError={( { currentTarget } ) => {
										currentTarget.onerror = null
										currentTarget.src = fileReguler
									}}
								/>
								<p style={{ margin: 0, fontWeight: "500" }}>
									Upper Jaw<span className="text-danger">*</span>
								</p>
							</label>
							<span className="d-flex" style={{ alignItems: "center", flexGrow: 1 }}>
								<p
									style={{
										margin: 0,
										color: "#424242",
										fontSize: "14px",
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
										maxWidth: "150px",
									}}
								>
									{scan.patient_scan_upper.file ? scan.patient_scan_upper.file.name : ""}
								</p>
								{scan.patient_scan_upper.file && (
									<CloseButton
										className="ms-2"
										onClick={() =>
											setScan( "patient_scan_upper", {
												additionalInfo: {},
												file: null,
											} )
										}
										hidden={!scan.patient_scan_upper}
									/>
								)}
							</span>
							<input
								hidden
								id="file-input-upper"
								type="file"
								onChange={( e: React.ChangeEvent<HTMLInputElement> ) =>
									fileImageSaver( e, "patient_scan_upper" )
								}
							/>
						</div>
					</Col>

					<Col>
						<div
							className="d-flex gap-3"
							style={{
								alignItems: "center",
								padding: "8px",
								border: "1px solid #ced4da",
								borderRadius: "6px",
								backgroundColor: "#f9f9f9",
							}}
						>
							{" "}
							<label
								style={{
									cursor: "pointer",
									alignItems: "center",
									gap: "8px",
								}}
								htmlFor="file-input-lower"
								className="d-flex"
							>
								<img
									src={
										scan.patient_scan_lower.file
											? URL.createObjectURL( scan.patient_scan_lower.file )
											: photosLibrary[ "patient_scan_lower" ]
									}
									className="upload-file-icon"
									style={{
										objectFit: "cover",
									}}
									onError={( { currentTarget } ) => {
										currentTarget.onerror = null
										currentTarget.src = fileReguler
									}}
								/>
								<p style={{ margin: 0, fontWeight: "500" }}>
									Lower Jaw<span className="text-danger">*</span>
								</p>
							</label>
							<span className="d-flex">
								<p
									style={{
										margin: 0,
										color: "#424242",
										fontSize: "14px",
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
										maxWidth: "150px",
									}}
								>
									{scan.patient_scan_lower.file ? scan.patient_scan_lower.file.name : ""}
								</p>
								{scan.patient_scan_lower.file && (
									<CloseButton
										className="ms-2"
										onClick={() =>
											setScan( "patient_scan_lower", {
												additionalInfo: {},
												file: null,
											} )
										}
										hidden={!scan.patient_scan_lower}
									/>
								)}
							</span>
							<input
								hidden
								id="file-input-lower"
								type="file"
								onChange={( e: React.ChangeEvent<HTMLInputElement> ) =>
									fileImageSaver( e, "patient_scan_lower" )
								}
							/>
						</div>
					</Col>
				</Row>
			</Form>
		</React.Fragment>
	)
}

export const UploadFromScanner = () => {
	return (
		<React.Fragment>
			<Row className="mt-4">
				<div className="d-flex gap-5">
					<p>Scanner: </p>
					<Form.Check type="radio" reverse label="iTero" name="selectedscanner" value={"iTero"} />
					<Form.Check type="radio" reverse label="3Shape" name="selectedscanner" value={"3Shape"} />
					<Form.Check type="radio" reverse label="Other" name="selectedscanner" value={"Other"} />
				</div>
			</Row>
		</React.Fragment>
	)
}

export default PatientScanTab
