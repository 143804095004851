import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query"
import axios from "axios"
import { z } from "zod"
import { queryClient } from "../providers/QueryProvider"
import { constants } from "./CelebApiConstants"
import { CelebRequestGeneratorService } from "./CelebRequestGenerator.Service"
import { TreatmentTaskCompleteRequest } from "./dtos/TreatmentTaskCompleteRequest.dto"
export class CelebTreatmentTaskService {
	static _instance: CelebTreatmentTaskService = new CelebTreatmentTaskService()

	private constructor() {
		CelebTreatmentTaskService._instance = this
	}

	static getInstance(): CelebTreatmentTaskService {
		return CelebTreatmentTaskService._instance
	}

	async getMyTasks(path: "me" | "awaiting", queryParams: UseTreatmentServiceQueryProps) {
		const queryStringArray: string[] = []
		Object.keys(queryParams).forEach((data) => {
			queryStringArray.push(data + "=" + queryParams[data])
		})
		const queryString = "?" + queryStringArray.join("&")
		const requestUrl = `${constants.TREATMENT_TASKS}/${path}${queryString}`
		return await CelebRequestGeneratorService.processGetRequest(requestUrl)
	}

	async getAwaitingTasks(queryParams: UseTreatmentServiceQueryProps) {
		const queryStringArray: string[] = []
		Object.keys(queryParams).forEach((data) => {
			queryStringArray.push(data + "=" + queryParams[data])
		})

		const queryString = "?" + queryStringArray.join("&")
		const requestUrl = `${constants.TREATMENT_TASKS}/awaiting${queryString}`

		return await CelebRequestGeneratorService.processGetRequest(requestUrl)
	}

	async putApproveApi(treatment: TreatmentArgs) {
		const requestUrl = `/api/api/approve/${treatment.treatmentId}`
		return axios.put(requestUrl, treatment.payload)
	}

	async getDoctorTasks(queryParams: any[]) {
		const queryStringArray = []
		for (let index = 0; index < queryParams.length; ++index) {
			queryStringArray.push(queryParams[index].name + "=" + queryParams[index].value)
		}
		const queryString = "?" + queryStringArray.join("&")
		const requestUrl = `${constants.TREATMENT_TASKS}/doctor/me${queryString}`

		return await CelebRequestGeneratorService.processGetRequest(requestUrl)
	}

	async getTasksForTreatment(treatmentId: string) {
		const requestUrl = `${constants.TREATMENT_TASKS}/treatment/${treatmentId}`

		return await CelebRequestGeneratorService.processGetRequest(requestUrl)
	}

	async updateTaskStatus(payload: TreatmentTaskCompleteRequest) {
		const requestUrl = `${constants.TREATMENT_TASKS}/completeTasks`
		return await CelebRequestGeneratorService.processPostRequest(requestUrl, payload)
	}

	async getRollbackTasksDetails(payload: any) {
		const requestUrl = `${constants.SERVICE_TASKS}/byids`
		return await CelebRequestGeneratorService.processPostRequest(requestUrl, payload)
	}
}

export const listTaskQueryKeyFn = (props?: UseTreatmentServiceQueryProps) =>
	["treatment", props].filter(Boolean)

export const useTaskServicesQuery = ({
	path,
	dataProps,
}: {
	path: "me" | "awaiting"
	dataProps: UseTreatmentServiceQueryProps
}) => {
	return useQuery({
		queryKey: ["treatment", dataProps, constants.TREATMENT_TASKS],
		queryFn: () =>
			CelebTreatmentTaskService.getInstance()
				.getMyTasks(path, dataProps)
				.then((res) => ({ data: res.data.data, total: res.headers["x-total-count"] }))
				.then(({ data, total }: any) => {
					return {
						treatment: data,
						total: total,
					}
				}),
	})
}

export const useRollBackTaskListQuery = (payload: unknown) =>
	useSuspenseQuery({
		queryKey: ["data", payload],
		queryFn: () =>
			CelebTreatmentTaskService.getInstance()
				.getRollbackTasksDetails(payload)
				.then((res) => res.data.data)
				.then((data) => data),
	})

export const updateTaskStatusMutation = () => {
	return useMutation({
		mutationFn: (payload: any) => {
			return CelebTreatmentTaskService.getInstance().updateTaskStatus(payload)
		},
		onSuccess: () => {
			return queryClient.clear()
		},
	})
}

export const useMutationApprove = () => {
	return useMutation({
		mutationFn: (treatment: TreatmentArgs) => {
			return CelebTreatmentTaskService.getInstance().putApproveApi(treatment)
		},
		onSuccess: () => {
			queryClient.invalidateQueries()
			return queryClient.invalidateQueries({
				queryKey: ["ApproveTask"],
				exact: false,
			})
		},
	})
}

type TreatmentArgs = {
	treatmentId: string
	payload?: {}
}

export type UseTreatmentServiceQueryProps = {
	serviceId: string
	limit: string
	search?: string
	offset: string
	status: string
	[key: string]: string | undefined | any[]
}

export type UseSearchTreatmentMutationProps = {
	serviceId: string
	limit: string
	search?: string
	offset: string
	[key: string]: string | undefined
}

export const TreatmentSchema = z.object({
	id: z.string(),
	note: z.string(),
	uploadFileUri: z.null(),
	checkmark: z.null(),
	providerCategoryId: z.string(),
	consumerCategoryId: z.string(),
	rollingBackToTask: z.null(),
	doctorsWithAccess: z.array(z.string()),
	taskStartDate: z.string(),
	taskEndDate: z.string(),
	replaceOrderTask: z.boolean(),
	isReplaceOrder: z.boolean(),
	approvalTask: z.boolean(),
	isActive: z.boolean(),
	taskStatus: z.string(),
	taskRunId: z.string(),
	taskAssignedRole: z.string(),
	status: z.string(),
	serviceId: z.string(),
	taskId: z.string(),
	patientId: z.string(),
	doctorId: z.string(),
	treatmentId: z.string(),
	createdAt: z.string(),
	updatedAt: z.string(),
	treatmentPlanServiceTask: z.object({
		name: z.string(),
		writeNote: z.boolean(),
		uploadFile: z.boolean(),
		checkmark: z.boolean(),
		rollBackToTask: z.array(z.unknown()),
		hasCustomMessage: z.boolean(),
	}),
	treatmentPlanPatient: z.object({
		firstName: z.string(),
		lastName: z.string(),
		entityId: z.number(),
	}),
	treatmentPlanDoctor: z.object({
		firstName: z.string(),
		lastName: z.string(),
	}),
	treatmentPlanServiceTaskTreatment: z.object({
		id: z.string().optional(),
		editorFilesStatus: z.string(),
		createdAt: z.string(),
		isReplacementOrder: z.boolean(),
		treatmentPlanDoctorAddress: z.object({
			id: z.string(),
			na: z.string(),
			ad: z.string(),
			ci: z.string(),
			st: z.string(),
			co: z.string(),
			pi: z.string(),
		}),
		fileTreatmentPlanId: z.object({
			id: z.string(),
			fileUri: z.string(),
		}),
	}),
})

export const TaskCompletionFormSchema = z.object({
	note: z.string(),
	checkmark: z.boolean(),
	uploadFileUri: z.union([z.string(), z.instanceof(File)]).optional(), // Allow both string (URL) and File (object)
	uploadFileName: z.string().optional(),
	rollBackToTask: z.string(),
	file: z.any().optional(),
	customMessage: z.string().optional(),
	fileDetails: z
		.object({
			file: z.string(),
			name: z.string(),
		})
		.optional(),
	timeline: z
		.object({
			customMessage: z.string().optional(),
			fileDetails: z
				.object({
					file: z.string(),
					name: z.string(),
				})
				.optional(),
		})
		.optional(),
})

export const TreatmentTaskCompleteRequestSchema = z.object({
	taskForm: z.array(TaskCompletionFormSchema),
})
export type TreatmentSchemaType = z.infer<typeof TreatmentSchema>
export type TreatmentTaskCompleteRequestType = z.infer<typeof TreatmentTaskCompleteRequestSchema>
export type TaskCompletionFormType = z.infer<typeof TaskCompletionFormSchema>
