import { zodResolver } from "@hookform/resolvers/zod"
import { loadStripe } from "@stripe/stripe-js"
import React from "react"
import { Button, CloseButton, Col, Form, Row } from "react-bootstrap"
import { FormProvider, useForm } from "react-hook-form"
import fileIcon from "../../../assets/img/file.png"
import { constants } from "../../../services/CelebApiConstants"
import {
	useEditAccountInfoMutation,
	UserProfileSchema,
	UserProfileType,
} from "../../../services/CelebUser.Service"
import { useProfileDialogStore } from "../../../store/profileDialogStore"
import { userSessionStore } from "../../../store/userSessionStore"
import { StripeSharedKey } from "../../../utils/appConstants"
import { CheckDoctor, CheckGenericPermission } from "../../permissions/CheckPermision"
import { clearHash } from "../../utlity/GenricFunctions"
import SpinnerLoader from "../../utlity/SpinnerLoader"
import { uploadLogo } from "../../utlity/uploadFiles"
import { ProfileFormInput } from "./ProfileFormFields"

loadStripe(StripeSharedKey)
	.then((d) => console.warn("loaded stripe instantce"))
	.catch((e) => console.error(e))

const ProfileInfoTab = () => {
	return (
		<React.Fragment>
			<ProfileInfoTabImpl />
		</React.Fragment>
	)
}

const ProfileInfoTabImpl = () => {
	const { user, setSession, setShowToastJson } = userSessionStore()
	const [isLoading, setIsLoading] = React.useState(false)
	const picture = user.profilePictureUri ? constants.IMAGEURL + user.profilePictureUri : null
	const [profilePicture, setProfilePicture] = React.useState<null | string>(picture)
	const refImage = React.useRef<HTMLInputElement | null>(null)
	const profileStore = useProfileDialogStore()
	const clearHashClear = clearHash()
	const methods = useForm<UserProfileType>({
		defaultValues: {
			...user,
		},
		mode: "all",
		resolver: zodResolver(UserProfileSchema),
	})
	const changeProfilePicture = (image: FileList | null) => {
		if (image?.length) {
			setProfilePicture(URL.createObjectURL(image[0]))
		}
	}

	const clearProfilePicture = (e: React.MouseEvent) => {
		e.stopPropagation()
		setProfilePicture(null)
	}

	const { mutate: saveModifiedInfo } = useEditAccountInfoMutation()

	const onModifyInfo = async (data: UserProfileType) => {
		setIsLoading(true)
		const image = refImage.current?.files
		if (image?.length) {
			const imageUrl = await uploadLogo(image)
			data.profilePictureUri = imageUrl
		} else {
			data.profilePictureUri = user.profilePictureUri
		}
		localStorage.setItem("user", JSON.stringify(data))
		setSession(data)
		saveModifiedInfo(data)
		setShowToastJson({
			visible: true,
			status: "Light",
			message: "Your Profile Information Updated Sucessfully",
		})
		setIsLoading(false)
	}

	return (
		<React.Fragment>
			<FormProvider {...methods}>
				<Form onSubmit={methods.handleSubmit(onModifyInfo, (e) => console.error(e))}>
					<section className="d-flex justify-content-between my-4">
						<span className="d-flex">
							<div className="mx-4 my-2">
								<Col>
									<ProfileFormInput name="firstName" autoFocus required label="First Name" />
								</Col>
								<Col>
									<ProfileFormInput name="lastName" required label="Last Name" />
								</Col>
							</div>
							<CheckGenericPermission genericLabel={"ProfilePhoto Update"}>
								<Row style={{ width: "50%", justifyContent: "center" }}>
									<div
										className="card text-bg-white mx-4 my-2"
										style={{
											width: "auto",
											maxWidth: "15rem",
											backgroundColor: "white !important",
											border: "none",
										}}
									>
										<img
											onClick={() => {
												refImage.current?.click()
											}}
											src={profilePicture != null ? profilePicture : fileIcon}
											className="img-fluid"
											alt="Upload Icon"
											style={{
												cursor: "pointer",
												width: "180px",
												height: "180px",
												objectFit: "contain",
											}}
										/>
										<div className="card-img-overlay">
											<CloseButton
												onClick={() => {
													refImage.current?.click()
												}}
												style={{
													position: "absolute",
													top: "0px",
													right: "0px",
													backgroundColor: "#E3E0F0",
													borderRadius: "50%",
												}}
											>
												<input
													type="file"
													accept="image/*"
													hidden
													ref={refImage}
													onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
														changeProfilePicture(e.currentTarget.files)
													}
												></input>
											</CloseButton>
										</div>
									</div>
									<Button
										className="d-block text-center mt-2"
										style={{
											background: "#E3E0F0",
											width: "10rem",
											height: "40px",
											borderRadius: "20px",
											alignContent: "center",
										}}
										onClick={() => refImage.current?.click()}
									>
										Profile Photo
									</Button>
								</Row>
							</CheckGenericPermission>
						</span>
					</section>
					<section className="my-4">
						<CheckDoctor>
							<ProfileFormInput
								as={"textarea"}
								name="clinicalPreference"
								label="Please list any clinical preferences you have below"
							/>
						</CheckDoctor>
						<CheckGenericPermission genericLabel="Email Notification">
							<Form.Check
								type="switch"
								id="status-switch"
								label="Email Notification"
								{...methods.register("emailNotificationsSubscribed")}
							/>
						</CheckGenericPermission>
					</section>
					<div className="d-flex justify-content-end gap-2">
						<CheckGenericPermission genericLabel={"ProfileInfo Update"}>
							<Button id="submitInfo" type="submit">
								{isLoading ? <SpinnerLoader /> : "Update"}
							</Button>
						</CheckGenericPermission>
						<Button
							type="button"
							variant="secondary"
							onClick={() => {
								profileStore.setModalOpen(false), clearHashClear()
							}}
						>
							Cancel
						</Button>
					</div>
				</Form>
			</FormProvider>
		</React.Fragment>
	)
}

export default ProfileInfoTab
