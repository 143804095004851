import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { useFormContext } from "react-hook-form"
import Select from "react-select"
import MoonLoader from "react-spinners/MoonLoader"
import {
	AddressSchemaType,
	useGetDoctorAddressesMutate,
} from "../../../services/CelebDoctorAddress.Service"
import { ReplaceOrderSchemaType } from "../../../services/CelebPatient.Service"
import { getPatientByIdQuery } from "../../../services/CelebTreatment.Service"
import { useDoctorListQuery, useGetAllSubUsers } from "../../../services/CelebUser.Service"
import { usePatientDialogStore } from "../../../store/patientDailogStore"
import { userSessionStore } from "../../../store/userSessionStore"
import { PatientEthenicities } from "../../../utils/appConstants"
import PatientInputForm, {
	PatientDateForm,
	PatientOtherForm,
	PatientSelectForm,
} from "./PatientFormFields"

const PatientInfoTab = () => {
	return (
		<React.Suspense
			fallback={<MoonLoader style={{ position: "absolute", top: "50%", left: "50%" }} />}
		>
			<PatientInfoImpl />
		</React.Suspense>
	)
}

const PatientInfoImpl = () => {
	const methods = useFormContext<ReplaceOrderSchemaType>()
	const { mutateAsync: getDoctorAddressById } = useGetDoctorAddressesMutate()
	useDoctorListQuery()
	const { setAllOffices, allOffices, doctorList, customErrors } = usePatientDialogStore()
	const sessionUser = userSessionStore((state: any) => state.user)
	const { data: subUsers } = useGetAllSubUsers()
	const PatientDialog = usePatientDialogStore()
	const { data: defaultValuesEdit } = getPatientByIdQuery(PatientDialog.replaceOpen.id)

	React.useEffect(() => {
		formEditPopulate()
	}, [])

	const formEditPopulate = () => {
		methods.setValue("selectedOffice", defaultValuesEdit.treatmentPlanDoctorAddressId)
		const dob = new Date(defaultValuesEdit.patient.dob)
		const setDate: HTMLInputElement | null = document.getElementById("date") as HTMLInputElement
		if (setDate) {
			setDate.value = dateFormater(dob)
		}
		getDoctorAddress({ label: "", value: sessionUser.id })
	}

	const getDoctorAddress = async (e: { label: string; value: string }) => {
		const data = await getDoctorAddressById(e.value)
		methods.setValue("assignedDoctor", e.value)
		methods.setValue("doctorsWithAccess", [e.value])
		methods.setValue("doctorsWithAccess", [e.value])
		setAllOffices(data)
	}

	return (
		<React.Fragment>
			<Form onSubmit={methods.handleSubmit((x) => console.warn(x))}>
				<Col>
					<Row className="p-2">
						<Col>
							<PatientInputForm name="firstName" required label="First Name" />
						</Col>
						<Col>
							<PatientInputForm name="lastName" required label="Last Name" />
						</Col>
						<Col>
							<PatientInputForm name="phone" label="Phone Number" type="number" />
						</Col>
					</Row>

					<Row className="p-2">
						<Col>
							<PatientSelectForm name="gender" required label="Gender">
								<option value={""}>Select Gender</option>
								<option value={"male"}>Male</option>
								<option value={"female"}>Female</option>
								<option value={"other"}>Other</option>
							</PatientSelectForm>
						</Col>
						<Col>
							<PatientSelectForm name="ethenicity" label="Ethnicity">
								<option>Select Ethnicity</option>
								{Object.keys(PatientEthenicities).map((ethnicity) => {
									return (
										<option key={ethnicity} value={ethnicity}>
											{PatientEthenicities[ethnicity]}
										</option>
									)
								})}
							</PatientSelectForm>
						</Col>
						<Col className="dateCol">
							<PatientDateForm
								name="dob"
								defaultValue={methods.watch("dob")}
								autoFocus
								required
								label="Date of Birth"
							/>
						</Col>
					</Row>
					<div className="d-flex p-2 w-100 gap-4">
						<div className="w-100">
							<PatientInputForm name="email" label="Patient Email Address" type="email" />
							<p className="text-danger">{customErrors.email}</p>
						</div>

						{sessionUser.isAdmin && (
							<div className="w-100">
								<PatientOtherForm name="assignedDoctor" label="Doctor to Assign" required>
									<Select
										onChange={(e: any) => {
											getDoctorAddress(e)
										}}
										defaultValue={doctorList.find((obj) => obj.value === methods.watch("doctorId"))}
										options={doctorList}
									/>
								</PatientOtherForm>
							</div>
						)}

						<div className="w-100">
							<PatientSelectForm name="selectedOffice" required label="Select Office">
								<option value={defaultValuesEdit.treatmentPlanDoctorAddressId}>
									{defaultValuesEdit.treatmentPlanDoctorAddress.name}
								</option>
								{allOffices
									.filter((office) => office.id !== defaultValuesEdit.treatmentPlanDoctorAddressId)
									.map((office: AddressSchemaType) => {
										return <option value={office.id}>{office.name}</option>
									})}
							</PatientSelectForm>
						</div>
					</div>
					{subUsers.data.length > 0 && (
						<div>
							<Row className="mt-4">
								<Col>Visibility</Col>
							</Row>

							<div className="d-flex flex-row">
								<div className="p-2 ms-2">
									<Form.Check label="Self" reverse {...methods.register("doctorsWithAccess")} />
								</div>
								{subUsers.data.map((subUser: any) => {
									return (
										<div className="p-2 ms-2">
											<Form.Check
												label={`${subUser.firstName} ${subUser.lastName}`}
												{...methods.register("doctorsWithAccess")}
											/>
										</div>
									)
								})}
							</div>
						</div>
					)}
				</Col>
			</Form>
			<footer className="d-flex m-3 position-absolute bottom-0 end-0">
				<Button className="m-0" onClick={() => (window.location.hash = "#Scan")}>
					<FontAwesomeIcon
						icon={faChevronRight}
						style={{ objectFit: "contain", width: "1rem", height: "1rem" }}
						className="m-0 p-0"
					/>
				</Button>
			</footer>
		</React.Fragment>
	)
}

export const dateFormater = (dob: Date) => {
	const formattedDate =
		dob.getFullYear() +
		"-" +
		String(dob.getMonth() + 1).padStart(2, "0") +
		"-" +
		String(dob.getDate()).padStart(2, "0") +
		"T" +
		String(dob.getHours()).padStart(2, "0") +
		":" +
		String(dob.getMinutes()).padStart(2, "0")
	return formattedDate
}
export default PatientInfoTab
