import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import {
	Badge,
	Button,
	Dropdown,
	DropdownButton,
	OverlayTrigger,
	Popover,
	Table,
} from "react-bootstrap"
import Moment from "react-moment"
import { Params, useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import {
	PatientEditSchema,
	useTaskCompleteServicesQuery,
	useTreatmentStatusMutation,
} from "../../services/CelebTreatment.Service"
import CompletedTableSkelton from "../../skelton-components/completedTableSkelton"
import { useCommonStore } from "../../store/commonStore"
import { usePatientDialogStore } from "../../store/patientDailogStore"
import { userSessionStore } from "../../store/userSessionStore"
import { TreatmentStates } from "../../utils/appConstants"
import { CheckGenericPermission } from "../permissions/CheckPermision"
import { infoMapperNames } from "../utlity/GenricFunctions"
import SortButton from "../utlity/SortButton"
import CustomPagination from "../utlity/pagination-component"
import { Avatar } from "@mui/material"
import { constants } from "../../services/CelebApiConstants"
import ShowReplaceOrderTable from "./dashbordComponents/showReplaceOrderTable"
import { getButtonProps } from "./utils/dashboardHelpers"

const CompletedTable = () => {
	const paramsData: Readonly<Params<string>> = useParams()
	const [searchParams, setSearchParams] = useSearchParams()
	const patientStore = usePatientDialogStore()
	const [currentPage, setCurrentPage] = React.useState<number>(0)
	const [sortingData, setSortingData] = React.useState<string[] | null>()
	const status = searchParams.get("status")
	const { mutateAsync: updateTaskStatus } = useTreatmentStatusMutation()
	const searchPatient = searchParams.get("searchPatient")
	const doctorIdParam = searchParams.get("doctorId")
	const PatientIdSearch = searchParams.get("PatientId")
	const { user } = userSessionStore()
	const { ReplaceOrderDetails, setReplaceOrderDetails } = useCommonStore()

	const { data: tableData } = useTaskCompleteServicesQuery({
		search: searchPatient || "",
		limit: "10",
		offset: currentPage + "",
		serviceId: paramsData.id ? paramsData.id : "",
		status: status ? status : "",
		...(doctorIdParam && { ["doctorId"]: doctorIdParam }),
		...(sortingData && {
			["sortingParams"]: JSON.stringify(sortingData),
		}),
	})

	const onTreatmentStateChange = async (treatmentId: string, statusTask: string) => {
		updateTaskStatus({ treatmentId, status: statusTask })
	}

	const addNewQueryParam = (patientID: PatientEditSchema) => {
		if (patientID == null || undefined) return
		if (
			ReplaceOrderDetails?.ReplaceOrderMark &&
			ReplaceOrderDetails?.PatientId == patientID.patientId
		) {
			return
		}
		if (patientID.id) {
			const params = new URLSearchParams(searchParams)
			params.set("PatientId", patientID.id)
			setSearchParams(params)
		}

		if (ReplaceOrderDetails?.PatientId !== patientID.patientId) {
			setReplaceOrderDetails(null)
		}
	}

	const handleSortChange = (apiParams: string[], sortOrder: "asc" | "desc" | undefined): void => {
		const updatedSortingData = sortOrder ? [...apiParams, sortOrder] : undefined
		setSortingData(updatedSortingData)
	}

	const renderPopover = (props: any, x: PatientEditSchema) => (
		<Popover id="popover-basic" {...props}>
			<ShowReplaceOrderTable activeOrder={x.id} patientId={x.patientId} />
		</Popover>
	)

	const onEdit = async (treatmentData: PatientEditSchema) => {
		if (treatmentData.isReplacementOrder) {
			return patientStore.setReplaceOpen({ id: treatmentData.id, isReplacementNew: false })
		}
		PatientIdSearch && patientStore.setTypesEditOpen(PatientIdSearch)
	}

	React.useEffect(() => {
		addNewQueryParam(tableData?.treatment[0])
	}, [status, paramsData.id, currentPage, tableData?.treatment[0]?.id])

	return (
		<React.Suspense fallback={<CompletedTableSkelton />}>
			<div
				style={{
					display: "flex",
					flex: 1,
					flexDirection: "column",
					height: "calc(90vh - 100px)",
				}}
			>
				<section
					style={{
						flex: 1,
						overflow: "auto",
					}}
				>
					<Table striped hover responsive={"xl"} className="text-start">
						<thead>
							<tr className="table-header" style={{ textWrap: "nowrap" }}>
								<th style={{ textAlign: "start" }}></th>

								<th style={{ textAlign: "start" }}>
									Patient Name{" "}
									<SortButton sortKey="patient#firstName" onSortChange={handleSortChange} />
								</th>
								<th style={{ textAlign: "start" }}>
									Patient ID{" "}
									<SortButton sortKey="patient#entityId" onSortChange={handleSortChange} />
								</th>
								<th style={{ textAlign: "start" }}>
									Submit Date <SortButton sortKey="createdAt" onSortChange={handleSortChange} />
								</th>
								<th style={{ textAlign: "start" }}>
									Clinic{" "}
									<SortButton
										sortKey="treatmentPlanDoctorAddress#name"
										onSortChange={handleSortChange}
									/>
								</th>
								<th style={{ textAlign: "start" }}>
									Doctor <SortButton sortKey="doctor#firstName" onSortChange={handleSortChange} />
								</th>
								<th style={{ textAlign: "start" }}>Action Buttons</th>
							</tr>
						</thead>
						<tbody>
							{tableData ? (
								tableData.treatment.map((x: PatientEditSchema) => {
									return (
										<>
											<tr
												style={{ verticalAlign: "middle" }}
												className="table-data"
												data-Active={
													searchParams.get("PatientId") === x.id ||
													x.patientId === ReplaceOrderDetails?.PatientId
												}
												onClick={(e) => {
													addNewQueryParam(x)
												}}
											>
												<td style={{ textAlign: "start" }}>
													{x.fileTreatmentPlanId?.fileUri && (
														<Avatar
															src={constants.IMAGEURL + x.fileTreatmentPlanId?.fileUri}
															variant="circular"
														/>
													)}
												</td>

												<td style={{ textAlign: "start", maxWidth: "10rem" }}>
													<div className="d-flex align-items-center justify-content-between gap-2">
														<CheckGenericPermission genericLabel="Patient Name">
															{infoMapperNames(x.patient, [
																"entityId",
																"dob",
																"id",
																"gender",
																"ethenicity",
																"email",
																"phone",
															])}{" "}
														</CheckGenericPermission>
														<div
															className="d-flex align-items-center justify-content-end gap-2"
															style={{ minWidth: "3rem" }}
														>
															<span style={{ minWidth: "30px" }}>
																<Badge bg="secondary" pill className="text-end">
																	{ReplaceOrderDetails?.PatientId == x.patientId &&
																		ReplaceOrderDetails?.ReplaceOrderMark}
																</Badge>
															</span>
															{x.isReplacementOrder && (
																<OverlayTrigger
																	trigger="click"
																	placement="bottom"
																	rootClose
																	rootCloseEvent="click"
																	overlay={(props) => renderPopover(props, x)}
																>
																	<FontAwesomeIcon
																		icon={faChevronDown}
																		style={{ cursor: "pointer", color: "#8faadc", zIndex: "1000" }}
																	/>
																</OverlayTrigger>
															)}
														</div>
													</div>
												</td>
												<td style={{ textAlign: "start" }}>
													{" "}
													<CheckGenericPermission genericLabel="Patient ID">
														{x.patient.entityId}
													</CheckGenericPermission>
												</td>
												<td style={{ textAlign: "start" }}>
													<CheckGenericPermission genericLabel="Patient Submit Date">
														{<Moment date={x.createdAt} format="MM/DD/YYYY" />}
													</CheckGenericPermission>
												</td>
												<td style={{ textAlign: "start" }}>
													{" "}
													<CheckGenericPermission genericLabel="Patient Clinic">
														{x.treatmentPlanDoctorAddress.name}
													</CheckGenericPermission>
												</td>
												<td style={{ textAlign: "start" }}>
													{" "}
													<CheckGenericPermission genericLabel="Patient Assigned Doctor">
														Dr. {x.doctor.firstName + " " + x.doctor.lastName}
													</CheckGenericPermission>
												</td>
												<td style={{ textAlign: "start", textWrap: "nowrap" }}>
													<div className="d-flex gap-2">
														<CheckGenericPermission genericLabel={"View TP"}>
															<Button
																disabled={x.editorFilesStatus !== "processed" && user.isDoctor}
																className={`override-button ${getButtonProps(x.editorFilesStatus).className}`}
																as="a"
																target="_blank"
																href={`/editor/noTreatmentAction?treatmentId=${x.id}`}
																data-status={getButtonProps(x.editorFilesStatus).text}
															>
																<span className="button-text">View TP</span>
																<span className="status-text">
																	{getButtonProps(x.editorFilesStatus).text}
																</span>
																<span className="loader"></span>
															</Button>
														</CheckGenericPermission>
														<DropdownButton id="dropdown-basic-button" title="">
															<CheckGenericPermission genericLabel={"Patient Edit"}>
																<Dropdown.Item href="#PatientInfo" onClick={() => onEdit(x)}>
																	Edit
																</Dropdown.Item>
															</CheckGenericPermission>
															{status == "completed" && (
																<>
																	<CheckGenericPermission genericLabel={"Patient Replace"}>
																		<Dropdown.Item
																			href="#PatientInfo"
																			onClick={() =>
																				patientStore.setReplaceOpen({
																					id: x.id,
																					isReplacementNew: true,
																				})
																			}
																		>
																			Replace
																		</Dropdown.Item>
																	</CheckGenericPermission>
																	<CheckGenericPermission genericLabel={"Patient Archive"}>
																		<Dropdown.Item
																			onClick={() =>
																				onTreatmentStateChange(x.id, TreatmentStates.ARCHIVED)
																			}
																		>
																			Archive
																		</Dropdown.Item>
																	</CheckGenericPermission>
																</>
															)}

															{status == TreatmentStates.PAUSED && (
																<>
																	<Dropdown.Item
																		onClick={() =>
																			onTreatmentStateChange(x.id, TreatmentStates.ACTIVE)
																		}
																	>
																		Unpause
																	</Dropdown.Item>
																</>
															)}

															{status == TreatmentStates.ARCHIVED && (
																<>
																	<Dropdown.Item
																		onClick={() =>
																			onTreatmentStateChange(
																				x.id,
																				x.status == TreatmentStates.ACTIVE_ARCHIVED
																					? TreatmentStates.ACTIVE
																					: TreatmentStates.COMPLETED,
																			)
																		}
																	>
																		Unarchive
																	</Dropdown.Item>
																</>
															)}
															<CheckGenericPermission genericLabel={"Patient Transfer"}>
																<Dropdown.Item>Transfer</Dropdown.Item>
															</CheckGenericPermission>
														</DropdownButton>
													</div>
												</td>
											</tr>
										</>
									)
								})
							) : (
								<CompletedTableSkelton />
							)}
						</tbody>
					</Table>
				</section>
				<div style={{ marginTop: "auto", padding: "10px 0" }}>
					<CustomPagination
						currentPage={currentPage / 10}
						total={tableData?.total}
						onChange={(pagenumber) => setCurrentPage(pagenumber * 10)}
					/>
				</div>
			</div>
		</React.Suspense>
	)
}

export default CompletedTable
