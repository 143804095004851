import React from "react"
import { Button, Modal, Nav, Navbar } from "react-bootstrap"
import { FieldErrors, useFormContext } from "react-hook-form"
import { useLocation } from "react-router"
import useCustomErrorMarkerTry from "../../../hooks/patient/customErrorMarker"
import { queryClient } from "../../../providers/QueryProvider"
import { PatientSchemaType, usePatientEditMutation } from "../../../services/CelebPatient.Service"
import { getPatientTreatmentFilesQuery } from "../../../services/CelebTreatmentFiles.Service"
import { usePatientDialogStore } from "../../../store/patientDailogStore"
import { useTeethStore } from "../../../store/toothSelectionStore"
import { userSessionStore } from "../../../store/userSessionStore"
import { TreatmentFileCategory } from "../../../utils/appConstants"
import { clearHash } from "../../utlity/GenricFunctions"
import { uploadMedicalFiles } from "../patient-v2/PatientNavbar"
import "./Patient.css"
import { fileResult } from "./PatientPhotos.tab"
import { photosLibraryReset } from "./defaultFileShowCase"
import { uploadLogo } from "../../utlity/uploadFiles"
const PatientNavbar = () => {
	const { setValue, getValues, control } = useFormContext<PatientSchemaType>()
	const {
		cbctDocumentJson,
		individualPhoto,
		xRays,
		scan,
		patientCompositePhotoJson,
		profilePicture,
		navErrors,
		typesEditOpen,
		clearState,
		setErrorToShow,
		setTypesEditOpen,
		setModalOpen,
	} = usePatientDialogStore()
	const { mutateAsync: editPatient } = usePatientEditMutation()
	const { teethBonding, teethExtraction, resetTeethSelection } = useTeethStore()
	const { setShowToastJson } = userSessionStore()
	const { data: defaultTreatmentFiles } = getPatientTreatmentFilesQuery(typesEditOpen)
	const { customErrorMarker } = useCustomErrorMarkerTry()
	const locationHash = useLocation()
	const clearHashClear = clearHash()

	const additionalApiRequirments = async () => {
		setShowToastJson({
			visible: true,
			status: "Secondary",
			message: `Uploading Treatment Files..`,
			timer: 30000,
			uploadPercentage: 10,
		})
		const date = Date.now()
		const doctorAddress = getValues("selectedOffice")
		setValue("office", doctorAddress)
		setValue("treatmentPlan.teethBonding", teethBonding)
		setValue("treatmentPlan.teethExtraction", teethExtraction)
		setValue("treatmentPlan.treatmentStart", date)
		setValue("treatmentPlan.treatmentPlanDoctorAddressId", doctorAddress)

		control.handleSubmit(checkSecondaryFilesValidation, onError)()
	}

	const uploadDocumentsToServer = async () => {
		try {
			setModalOpen(false)
			setShowToastJson({
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files..`,
				timer: 30000,
				uploadPercentage: 20,
			})
			const uploadCbctData = await uploadFiles(cbctDocumentJson, TreatmentFileCategory.PATIENT_CBCT)
			setShowToastJson({
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files..`,
				timer: 30000,
				uploadPercentage: 30,
			})

			const uploadPhotoDataComposite = await uploadFiles(
				patientCompositePhotoJson,
				TreatmentFileCategory.PATIENT_PHOTO,
			)
			const uploadPhotoDataJson = await uploadFiles(
				individualPhoto,
				TreatmentFileCategory.PATIENT_PHOTO,
			)
			setShowToastJson({
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files..`,
				timer: 30000,
				uploadPercentage: 40,
			})
			const uploadXRaysData = await uploadFiles(xRays, TreatmentFileCategory.PATIENT_XRAY)
			setShowToastJson({
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files..`,
				timer: 30000,
				uploadPercentage: 50,
			})
			const uploadScanData = await uploadFiles(scan, TreatmentFileCategory.PATIENT_SCAN)
			setShowToastJson({
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files..`,
				timer: 30000,
				uploadPercentage: 60,
			})

			let uploadPatientProfile = ""
			if (profilePicture?.additionalInfo?.note == "delete this image") {
				uploadPatientProfile = ""
			} else if (profilePicture?.file) {
				uploadPatientProfile = await uploadLogo([profilePicture?.file] as any)
			} else {
				const profilePhoto: fileResult = defaultTreatmentFiles.find(
					(file: fileResult) => file.fileCategory === "patient_profile_photo",
				)
				uploadPatientProfile = profilePhoto?.fileUri
			}
			const uploadPatientProfilePhoto: UploadData[] = [
				{
					category: "patient_profile_photo",
					subCategory: "patient_profile_photo",
					originalFileName: "patient_profile_photo",
					uri: uploadPatientProfile,
				},
			]

			const treatmentFiles = [
				...uploadPatientProfilePhoto,
				...uploadCbctData,
				...uploadPhotoDataComposite,
				...uploadXRaysData,
				...uploadScanData,
				...uploadPhotoDataJson,
			].filter((file): file is UploadData =>
				file !== undefined && file.uri?.length >= 1 ? true : false,
			)
			setShowToastJson({
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files..`,
				timer: 15000,
				uploadPercentage: 80,
			})
			setValue("treatmentPlan.treatmentFiles", treatmentFiles)
			const treatmentType = getTreatmentType()
			setValue("treatmentPlan.treatmentType", treatmentType)
			control.handleSubmit(submit, onError)()
		} catch (error) {
			console.error(error)
			setShowToastJson({
				visible: true,
				status: "Secondary",
				message: `Uploading Treatment Files failed`,
				timer: 15000,
				uploadPercentage: 80,
			})
			setModalOpen(true)
		}
	}

	const uploadFiles = async (
		files: {
			[key: string]: {
				file: File | null
				additionalInfo: {
					note?: string
				}
			}
		},
		category: string,
	) => {
		const uploadPromises = Object.keys(files).map(async (fileKey) => {
			const file = files[fileKey]
			const tempObj = {
				category: "",
				subCategory: "",
				originalFileName: "",
				uri: "",
			}
			if (file.file) {
				return await uploadMedicalFiles(file.file, category, fileKey)
			} else {
				defaultTreatmentFiles.forEach((fileData: fileResult) => {
					if (fileData.fileSubCategory == fileKey) {
						tempObj.category = fileData.fileCategory
						tempObj.subCategory = fileData.fileSubCategory
						tempObj.originalFileName = fileData.originalFileName
						tempObj.uri = fileData.fileUri
					}
				})
				return tempObj
			}
		})
		return await Promise.all(uploadPromises)
	}

	const checkSecondaryFilesValidation = () => {
		if (customErrorMarker()) return null
		uploadDocumentsToServer()
	}

	const getTreatmentType = () => {
		const isLowerSelected =
			Object.entries(teethBonding).filter(
				([key, value]) => key.startsWith("L") && value === "selected",
			).length > 0

		const isUpperSelected =
			Object.entries(teethBonding).filter(
				([key, value]) => key.startsWith("U") && value === "selected",
			).length > 0

		if (isLowerSelected && isUpperSelected) {
			return "both"
		} else if (isLowerSelected) {
			return "lower"
		} else if (isUpperSelected) {
			return "upper"
		}

		return ""
	}

	const submit = (value: PatientSchemaType) => {
		editPatient({ id: typesEditOpen, payload: value })
			.then(() => {
				setShowToastJson({
					visible: true,
					status: "Secondary",
					message: `Uploading Treatment Files Completed..`,
					timer: 3000,
					uploadPercentage: 100,
				})
				setModalOpen(false)
				clearFormData()
			})
			.catch((error) => {
				setModalOpen(true)
				return error
			})
	}

	const onError = (errorData: FieldErrors<PatientSchemaType>) => {
		customErrorMarker(errorData)
	}

	const clearFormData = () => {
		resetTeethSelection()
		clearState()
		setTypesEditOpen(null)
		photosLibraryReset()
		clearHashClear()
		setErrorToShow("")
		queryClient.invalidateQueries()
	}
	return (
		<React.Fragment>
			<Navbar id="navbarScroll" as={Modal.Header}>
				<Nav variant="tabs" defaultActiveKey="#PatientInfo" className="d-flex mt-2 me-auto">
					<Nav.Link
						href="#PatientInfo"
						active={locationHash.hash == "#PatientInfo"}
						className={navErrors.infoTab}
					>
						Patient Info
					</Nav.Link>
					<Nav.Link
						href="#Scan"
						active={locationHash.hash == "#Scan"}
						className={navErrors.scanTab}
					>
						Scan
					</Nav.Link>
					<Nav.Link
						href="#Instructions"
						active={locationHash.hash == "#Instructions"}
						className={navErrors.instructions}
					>
						Instructions
					</Nav.Link>
					<Nav.Link
						href="#Photos"
						active={locationHash.hash == "#Photos"}
						className={navErrors.photos}
					>
						Photos
					</Nav.Link>
					<Nav.Link href="#X-rays" active={locationHash.hash == "#X-rays"}>
						X-rays
					</Nav.Link>
					<Nav.Link href="#CBCT" active={locationHash.hash == "#CBCT"}>
						CBCT
					</Nav.Link>
				</Nav>
				<span>
					<Button onClick={additionalApiRequirments} className="mx-2">
						Update
					</Button>
					<Button variant="secondary" onClick={clearFormData}>
						Cancel
					</Button>
				</span>
			</Navbar>
		</React.Fragment>
	)
}

export type UploadData = {
	category: string
	subCategory: string
	originalFileName: string
	uri: string
}

export default PatientNavbar
