import React from "react"
import { Button, CloseButton, Col, Form, Row } from "react-bootstrap"
import { useFormContext } from "react-hook-form"
import Select from "react-select"
import MoonLoader from "react-spinners/MoonLoader"
import {
	AddressSchemaType,
	useGetDoctorAddressesMutate,
	useInsertAddressMutation,
} from "../../../services/CelebDoctorAddress.Service"
import { PatientSchemaType } from "../../../services/CelebPatient.Service"
import { useDoctorListQuery, useGetAllSubUsers } from "../../../services/CelebUser.Service"
import { fileUploadingType, usePatientDialogStore } from "../../../store/patientDailogStore"
import { useProfileDialogStore } from "../../../store/profileDialogStore"
import { userSessionStore } from "../../../store/userSessionStore"
import { PatientEthenicities } from "../../../utils/appConstants"
import { CheckAdmin } from "../../permissions/CheckPermision"
import "./calender.css"

import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import patientProfileIcon from "../../../assets/img/patient-profile-icon.png"
import { ServiceAddress } from "../../../services/CelebService.Service"
import ProfileAddressAddDialog from "../profile-v2/ProfileAddressAdd.dialog"
import PatientInputForm, {
	PatientDateForm,
	PatientOtherForm,
	PatientSelectForm,
} from "./PatientFormFields"

const PatientInfoTab = () => {
	return (
		<React.Suspense
			fallback={<MoonLoader style={{ position: "absolute", top: "50%", left: "50%" }} />}
		>
			<PatientInfoImpl />
		</React.Suspense>
	)
}
const PatientInfoImpl = () => {
	const methods = useFormContext<PatientSchemaType>()
	const { mutateAsync: getDoctorAddressById } = useGetDoctorAddressesMutate()
	const { mutateAsync: addDoctorAddress } = useInsertAddressMutation()
	const { setAllOffices, allOffices, doctorList, profilePicture, setProfilePicture, customErrors } =
		usePatientDialogStore()
	const sessionUser = userSessionStore((state: any) => state.user)
	const { data: subUsers } = useGetAllSubUsers()
	const profileStore = useProfileDialogStore()
	const [loading, setLoading] = React.useState(false)
	const imageInputId = "file-upload"

	useDoctorListQuery()
	React.useEffect(() => {
		if (sessionUser.isAdmin == false) {
			getDoctorAddress({ label: "", value: sessionUser.id })
		}
		if (
			methods.watch("dob") === undefined ||
			methods.watch("dob") === null ||
			methods.watch("dob") === ""
		) {
			const date = new Date()
			const pastDate = new Date(date.getFullYear() - 12, date.getMonth(), date.getDate())
			methods.setValue("dob", pastDate.toISOString().split("T")[0])
		}
	}, [])

	const getDoctorAddress = async (e: { label: string; value: string }) => {
		const data = await getDoctorAddressById(e.value)
		methods.setValue("assignedDoctor", e.value)
		const doctorDataSet = methods.watch("doctorsWithAccess")
		doctorDataSet == undefined && methods.setValue("doctorsWithAccess", [e.value])
		setAllOffices(data)
	}

	const handleVisibilityChange = (selectedOptions: any) => {
		const dataSet = methods.watch("treatmentPlan.doctorsWithAccess")
		const selectedDoctor = selectedOptions.map((x: any) => x.value)
		if (dataSet != undefined) {
			methods.setValue("treatmentPlan.doctorsWithAccess", [...selectedDoctor])
		} else {
			methods.setValue("treatmentPlan.doctorsWithAccess", [...selectedDoctor])
		}
	}

	const addAdress = async (address: ServiceAddress) => {
		if (!address) return
		setLoading(true)
		if (sessionUser.isAdmin == true) {
			await addDoctorAddress({
				doctorId: methods.watch("assignedDoctor"),
				...address[0],
			} as AddressSchemaType)
			await getDoctorAddress({ label: "", value: methods.watch("assignedDoctor") })
		} else {
			await addDoctorAddress(address[0] as AddressSchemaType)
			await getDoctorAddress({ label: "", value: sessionUser.id })
		}
		setLoading(false)
	}

	const changeProfilePicture = (image: FileList | null) => {
		if (image?.length) {
			const file = image[0]
			const fileData: fileUploadingType = {
				file: file,
				additionalInfo: {},
			}
			setProfilePicture(fileData)
		}
	}

	const clearProfilePicture = () => {
		setProfilePicture({
			file: null,
			additionalInfo: {},
		})
	}

	const handleOfficeSelectChange = (e: any) => {
		if (e.target.value === "Add New Office" && methods.watch("assignedDoctor") !== null) {
			methods.setValue("selectedOffice", "")
			profileStore.setCreateOpen(true)
		}
	}

	return (
		<React.Fragment>
			<Form onSubmit={methods.handleSubmit((x) => console.warn(x))}>
				<Col>
					<Row className="p-2">
						<Col>
							<PatientInputForm name="firstName" autoFocus required label="First Name" />
						</Col>
						<Col>
							<PatientInputForm name="lastName" required label="Last Name" />
						</Col>
						<Col className="d-flex justify-content-center">
							<div className="d-flex flex-column align-items-center" style={{ width: "7.5rem" }}>
								<div
									className="card text-bg-white mb-2 position-relative"
									style={{
										width: "6rem",
										height: "6rem",
										border: "none",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<img
										onClick={() => document.getElementById(imageInputId)?.click()}
										src={
											profilePicture?.file
												? URL.createObjectURL(profilePicture.file)
												: patientProfileIcon
										}
										className="img-fluid"
										alt="Upload Icon"
										onError={(e) => (e.currentTarget.src = patientProfileIcon)}
										style={{
											width: "100%",
											height: "100%",
											objectFit: "contain",
											borderRadius: "10px",
											cursor: "pointer",
											backgroundColor: "#f0ecec",
										}}
									/>
									<CloseButton
										onClick={clearProfilePicture}
										hidden={!profilePicture?.file}
										style={{
											position: "absolute",
											top: "-5px",
											right: "-5px",
											backgroundColor: "#E3E0F0",
											borderRadius: "50%",
											zIndex: 2,
										}}
									/>

									<input
										type="file"
										accept="image/*"
										hidden
										id={imageInputId}
										onChange={(e) => changeProfilePicture(e.currentTarget.files)}
									/>
								</div>
								<Button
									className="d-block p-0 text-center"
									style={{
										background: "#E3E0F0",
										width: "5rem",
										height: "30px",
										borderRadius: "10px",
										alignContent: "center",
									}}
									onClick={() => document.getElementById(imageInputId)?.click()}
								>
									Profile
								</Button>
							</div>
						</Col>
					</Row>

					<Row className="p-2">
						<Col>
							<PatientInputForm name="phone" label="Phone Number" type="number" />
						</Col>
						<Col>
							<PatientSelectForm name="gender" required label="Gender">
								<option value={""}>Select Gender</option>
								<option value={"male"}>Male</option>
								<option value={"female"}>Female</option>
								<option value={"other"}>Other</option>
							</PatientSelectForm>
						</Col>
						<Col>
							<PatientSelectForm name="ethenicity" label="Ethnicity">
								<option>Select Ethnicity</option>
								{Object.keys(PatientEthenicities).map((ethenicity) => {
									return (
										<option key={ethenicity} value={ethenicity}>
											{PatientEthenicities[ethenicity]}
										</option>
									)
								})}
							</PatientSelectForm>
						</Col>
					</Row>
					<div className="d-flex p-2 w-100 gap-4">
						<Col className="dateCol">
							<PatientDateForm
								name="dob"
								defaultValue={methods.watch("dob")}
								autoFocus
								required
								label="Date of Birth"
							/>
						</Col>
						<div className="w-100">
							<PatientInputForm name="email" label="Email" type="email" />
							<p className="text-danger">{customErrors.email}</p>
						</div>
						<div className="w-100">
							{loading ? (
								<div className="m-4 p-3 text-primary spinner-border" role="status">
									<span className="visually-hidden">Loading...</span>
								</div>
							) : (
								<>
									<PatientSelectForm
										name="selectedOffice"
										required
										label="Select Office"
										placeholder="Please Select Office"
										onChange={handleOfficeSelectChange}
										disabled={!methods.watch("assignedDoctor")}
									>
										<option value={""}>Select Office</option>
										{allOffices.map((office: AddressSchemaType) => {
											return <option value={office.id}>{office.name}</option>
										})}
										<option
											hidden={!methods.watch("assignedDoctor")}
											style={{ fontWeight: "bold", backgroundColor: "#f2f2f2" }}
											value={"Add New Office"}
										>
											Add New Office
										</option>
									</PatientSelectForm>

									<ProfileAddressAddDialog cb={addAdress} />
								</>
							)}
						</div>
					</div>
					{subUsers.data.length >= 1 && (
						<div className="w-50">
							<Form.Group>
								<Form.Label>Visibility</Form.Label>
								<Select
									isMulti
									options={subUsers?.data?.map((subUser: any) => ({
										value: subUser.id,
										label: `${subUser.firstName} ${subUser.lastName}`,
									}))}
									onChange={handleVisibilityChange}
									value={methods.watch("treatmentPlan.doctorsWithAccess")?.map((x) => {
										if (subUsers.data.find((z: any) => z.id == x)?.firstName) {
											return {
												value: x,
												label: `${subUsers.data.find((z: any) => z.id == x)?.firstName}`,
											}
										}
									})}
									isSearchable
									isClearable
								/>
							</Form.Group>
						</div>
					)}
				</Col>
				<div style={{ width: "66%" }}>
					<CheckAdmin>
						<div style={{ width: "50%" }}>
							<PatientOtherForm name="assignedDoctor" label="Doctor to Assign" required>
								<Select
									value={
										doctorList.find((d) => d.value === methods.watch("assignedDoctor")) || null
									}
									onChange={(e: any) => {
										methods.setValue("assignedDoctor", e.value)
										getDoctorAddress(e)
									}}
									options={doctorList}
								/>
							</PatientOtherForm>
						</div>
					</CheckAdmin>
				</div>
			</Form>
			<footer className="d-flex m-3 position-absolute bottom-0 end-0">
				<Button onClick={(e) => (window.location.hash = "#Scan")}>
					<FontAwesomeIcon
						icon={faChevronRight}
						style={{ objectFit: "contain", width: "1rem", height: "1rem" }}
						className="m-0 p-0"
					/>
				</Button>
			</footer>
		</React.Fragment>
	)
}

export default PatientInfoTab
