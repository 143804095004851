import React from "react"
import { Button, Dropdown, DropdownButton, Form, Table } from "react-bootstrap"
import Moment from "react-moment"
import { Params, useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import {
	SearchTreatmentType,
	useSearchTreatment,
	useTreatmentStatusMutation,
} from "../../services/CelebTreatment.Service"
import CompletedTableSkelton from "../../skelton-components/completedTableSkelton"
import { useCommonStore } from "../../store/commonStore"
import { usePatientDialogStore } from "../../store/patientDailogStore"
import { useSidebarStore } from "../../store/sideBarStore"
import { useTaskCompletionDialogStore } from "../../store/taskCompletionDialogStore"
import { userSessionStore } from "../../store/userSessionStore"
import { TreatmentStates } from "../../utils/appConstants"
import {
	CheckAdmin,
	CheckDoctor,
	CheckGenericPermission,
	CheckOperator,
} from "../permissions/CheckPermision"
import SortButton from "../utlity/SortButton"
import CustomPagination from "../utlity/pagination-component"
import { Avatar } from "@mui/material"
import { constants } from "../../services/CelebApiConstants"
import { getButtonProps } from "./utils/dashboardHelpers"

const SearchTable = () => {
	return (
		<React.Suspense>
			<LandingPageImpl />
		</React.Suspense>
	)
}

const LandingPageImpl = () => {
	const paramsData: Readonly<Params<string>> = useParams()
	const [searchParams, setSearchParams] = useSearchParams()
	const newParams = new URLSearchParams(searchParams)
	const tasksStore = useTaskCompletionDialogStore()
	const patientStore = usePatientDialogStore()
	const commanStore = useCommonStore()
	const [currentPage, setCurrentPage] = React.useState<number>(0)
	const status = searchParams.get("status")
	const [sortingData, setSortingData] = React.useState<string[] | null>()
	const searchPatient = searchParams.get("searchPatient")
	const sideBarStore = useSidebarStore()
	const { mutateAsync: updateTaskStatus } = useTreatmentStatusMutation()
	const { user } = userSessionStore()
	const { data: tableData } = useSearchTreatment({
		search: searchPatient || "",
		limit: "10",
		offset: currentPage + "",
		serviceId: paramsData.id ? paramsData.id : "",
		...(sortingData && {
			["sortingParams"]: JSON.stringify(sortingData),
		}),
	})

	React.useEffect(() => {
		commanStore.setTableData(tableData?.total || 0)
	}, [tableData?.total])

	const idMapper = (patientID: SearchTreatmentType) => {
		if (patientID.id) {
			return patientID.id
		}
	}

	const addNewQueryParam = (patientID: SearchTreatmentType) => {
		const id = idMapper(patientID)
		const newParamsLocal = new URLSearchParams(searchParams)
		id && newParamsLocal.set("PatientId", id)
		setSearchParams(newParamsLocal)
		sideBarStore.setTimelineData(patientID)
	}

	const onTreatmentStateChange = async (
		patientID: SearchTreatmentType,
		statusLocal: TreatmentStates,
	) => {
		const id = idMapper(patientID)
		id && updateTaskStatus({ treatmentId: id, status: statusLocal })
	}

	const handleSortChange = (apiParams: string[], sortOrder: "asc" | "desc" | undefined): void => {
		const updatedSortingData = sortOrder ? [...apiParams, sortOrder] : undefined
		setSortingData(updatedSortingData)
	}

	React.useEffect(() => {
		tableData?.treatment[0] && addNewQueryParam(tableData?.treatment[0])
	}, [tableData?.treatment[0], status])

	const onEdit = (patientID: SearchTreatmentType) => {
		const id = idMapper(patientID)
		id && patientStore.setTypesEditOpen(id)
	}

	return (
		<React.Suspense fallback={<CompletedTableSkelton />}>
			<div
				style={{
					display: "flex",
					flex: 1,
					flexDirection: "column",
					height: "calc(90vh - 100px)",
				}}
			>
				<section
					style={{
						flex: 1,
						overflow: "auto",
					}}
				>
					<Table striped hover className="text-start">
						<thead>
							<tr className="table-header" style={{ textWrap: "nowrap" }}>
								{status == "active" && <th>Check</th>}
								<th style={{ textAlign: "start" }}></th>
								<th style={{ textAlign: "start" }}>
									Patient Name{" "}
									<SortButton sortKey="patient#firstName" onSortChange={handleSortChange} />
								</th>
								<th style={{ textAlign: "start" }}>
									Patient ID
									<SortButton sortKey="patient#entityId" onSortChange={handleSortChange} />
								</th>
								<th style={{ textAlign: "start" }}>
									Task{" "}
									<SortButton sortKey="tpProviderCategoryId#name" onSortChange={handleSortChange} />
								</th>
								<th style={{ textAlign: "start" }}>
									Submit Date
									<SortButton sortKey="createdAt" onSortChange={handleSortChange} />
								</th>
								<th style={{ textAlign: "start" }}>
									Clinic{" "}
									<SortButton
										sortKey="treatmentPlanDoctorAddress#name"
										onSortChange={handleSortChange}
									/>
								</th>
								<th style={{ textAlign: "start" }}>
									Doctor <SortButton sortKey="doctor#firstName" onSortChange={handleSortChange} />
								</th>
								<th style={{ textAlign: "start" }}>Action Buttons</th>
							</tr>
						</thead>
						<tbody>
							{tableData ? (
								tableData?.treatment?.map((x: SearchTreatmentType) => {
									return (
										<tr
											style={{ verticalAlign: "middle" }}
											className="table-data"
											data-Active={newParams.get("PatientId") === idMapper(x)}
											onClick={() => {
												addNewQueryParam(x)
											}}
										>
											<td style={{ textAlign: "start" }}>
												{x.fileTreatmentPlanId[0]?.fileUri && (
													<Avatar
														src={constants.IMAGEURL + x.fileTreatmentPlanId[0]?.fileUri}
														variant="circular"
													/>
												)}
											</td>

											{status == "active" && (
												<td onClick={(event) => event?.stopPropagation()}>
													<span className="d-flex gap-2">
														{status == "active" && (
															<CheckGenericPermission genericLabel="Task Select">
																<Form.Check
																	checked={
																		tasksStore?.landingPageTasks.find((z) => z.id === x?.id)
																			? true
																			: false
																	}
																></Form.Check>
															</CheckGenericPermission>
														)}
													</span>
												</td>
											)}

											<td style={{ textAlign: "start" }}>
												<CheckGenericPermission genericLabel="Patient Name">
													{x?.patient?.firstName + " " + x?.patient?.lastName}
												</CheckGenericPermission>
											</td>
											<td style={{ textAlign: "start" }}>
												<CheckGenericPermission genericLabel="Patient ID">
													{x?.patient?.entityId}
												</CheckGenericPermission>
											</td>
											<td style={{ textAlign: "start" }}>
												<CheckGenericPermission genericLabel="Patient ID">
													<CheckDoctor>{x?.tpConsumerCategoryId?.name}</CheckDoctor>
													<CheckOperator>{x?.tpProviderCategoryId?.name}</CheckOperator>
													<CheckAdmin>{x?.tpProviderCategoryId?.name}</CheckAdmin>
												</CheckGenericPermission>
											</td>
											<td style={{ textAlign: "start" }}>
												{" "}
												<CheckGenericPermission genericLabel="Patient Submit Date">
													{<Moment date={x?.patient?.createdAt} format="MM/DD/YYYY" />}
												</CheckGenericPermission>
											</td>
											<td style={{ textAlign: "start" }}>
												{" "}
												<CheckGenericPermission genericLabel="Patient Clinic">
													{x?.treatmentPlanDoctorAddress?.name}
												</CheckGenericPermission>
											</td>
											<td style={{ textAlign: "start" }}>
												{" "}
												<CheckGenericPermission genericLabel="Patient Assigned Doctor">
													Dr. {x?.doctor?.firstName + " " + x?.doctor?.lastName}
												</CheckGenericPermission>
											</td>
											<td style={{ textAlign: "start", textWrap: "nowrap" }}>
												<div className="d-flex gap-2">
													<CheckGenericPermission genericLabel={"View TP"}>
														<Button
															disabled={x.editorFilesStatus != "processed" && user.isDoctor}
															className={`override-button ${getButtonProps(x.editorFilesStatus).className}`}
															as="a"
															target="_blank"
															href={
																"/editor/" +
																x?.tpProviderCategoryId?.id +
																"?treatmentId=" +
																idMapper(x)
															}
															data-status={getButtonProps(x.editorFilesStatus).text}
														>
															<span className="button-text">View TP</span>
															<span className="loader"></span>
															<span className="status-text">
																{getButtonProps(x.editorFilesStatus).text}
															</span>
														</Button>
													</CheckGenericPermission>

													<DropdownButton id="dropdown-basic-button" title="">
														<CheckGenericPermission genericLabel={"Patient Replace"}>
															{status == "completed" ? (
																<Dropdown.Item
																	href="#PatientInfo"
																	onClick={() =>
																		patientStore.setReplaceOpen({
																			id: x?.id,
																			isReplacementNew: true,
																		})
																	}
																>
																	Replace
																</Dropdown.Item>
															) : (
																""
															)}
														</CheckGenericPermission>

														<CheckGenericPermission genericLabel={"Patient Edit"}>
															{x.status == TreatmentStates.ACTIVE && (
																<Dropdown.Item href="#PatientInfo" onClick={() => onEdit(x)}>
																	Edit
																</Dropdown.Item>
															)}
														</CheckGenericPermission>

														<CheckGenericPermission genericLabel={"Patient Pause"}>
															<Dropdown.Item
																onClick={() =>
																	onTreatmentStateChange(
																		x,
																		x.status === TreatmentStates.PAUSED
																			? TreatmentStates.ACTIVE
																			: TreatmentStates.PAUSED,
																	)
																}
															>
																{x.status === TreatmentStates.PAUSED ? "Unpause" : "Pause"}
															</Dropdown.Item>
														</CheckGenericPermission>

														{x.status == TreatmentStates.ACTIVE && (
															<CheckGenericPermission genericLabel={"Patient Transfer"}>
																<Dropdown.Item>Transfer</Dropdown.Item>
															</CheckGenericPermission>
														)}

														<CheckGenericPermission genericLabel={"Patient Archive"}>
															<Dropdown.Item
																onClick={() => {
																	onTreatmentStateChange(
																		x,
																		x.status === TreatmentStates.ARCHIVED
																			? TreatmentStates.COMPLETED
																			: TreatmentStates.ACTIVE_ARCHIVED
																				? TreatmentStates.ACTIVE
																				: TreatmentStates.ARCHIVED,
																	)
																}}
															>
																{x.status === TreatmentStates.ARCHIVED ||
																x.status === TreatmentStates.ACTIVE_ARCHIVED
																	? "Unarchive"
																	: "Archive"}
															</Dropdown.Item>
														</CheckGenericPermission>
													</DropdownButton>
												</div>
											</td>
										</tr>
									)
								})
							) : (
								<CompletedTableSkelton />
							)}
						</tbody>
					</Table>
				</section>
				<div style={{ marginTop: "auto", padding: "10px 0" }}>
					<CustomPagination
						currentPage={currentPage / 10}
						total={tableData?.total}
						onChange={(pagenumber) => setCurrentPage(pagenumber * 10)}
					/>
				</div>
			</div>
		</React.Suspense>
	)
}

export default SearchTable
