import React from "react"
import {
	Alert,
	Badge,
	Button,
	ButtonGroup,
	Dropdown,
	Nav,
	Navbar,
	NavDropdown,
	ProgressBar,
} from "react-bootstrap"
import { Link, Params, useNavigate, useParams, useSearchParams } from "react-router-dom"
import Select from "react-select"
import { useGetAssignedDoctors } from "../../services/CelebSalesRepresentative.service"
import {
	CategoriesResponseType,
	CategoriesType,
	getServiceTreatmentCount,
	useListAllServicesQuery,
} from "../../services/CelebService.Service"
import { useCommonStore } from "../../store/commonStore"
import { usePatientDialogStore } from "../../store/patientDailogStore"
import { useTaskCompletionDialogStore } from "../../store/taskCompletionDialogStore"
import { userSessionStore } from "../../store/userSessionStore"
import { TaskCategory } from "../../utils/appConstants"
import SearchInput from "../form/SearchInput"
import {
	CheckAdmin,
	CheckDoctor,
	CheckGenericPermission,
	CheckOperator,
	CheckSales,
} from "../permissions/CheckPermision"

const SubNavBar = () => {
	const paramsData: Readonly<Params<string>> = useParams()
	const [searchParams, setSearchParams] = useSearchParams()
	const taskCompletionStore = useTaskCompletionDialogStore()
	const patientStore = usePatientDialogStore()
	const sessionStore = userSessionStore()
	const tableData = useCommonStore((state) => state.tableData)
	const status = searchParams.get("status")
	const { data: categoriesData } = useListAllServicesQuery()
	const doctorIdParam = searchParams.get("doctorId")
	const { data: serviceTreatmentCounts } = getServiceTreatmentCount(
		paramsData?.id,
		status + "",
		doctorIdParam,
	)
	const { data: doctorList } = useGetAssignedDoctors()
	const navigate = useNavigate()
	const category = searchParams.get("provide")
	const [selectedCategory, setSelectedCategory] = React.useState<string | null>(category || null)
	const [selectedCategoryName, setSelectedCategoryName] = React.useState<string | null>(
		category || null,
	)
	const categoryconsume = searchParams.get("consume")
	const doctorId = searchParams.get("doctorId")
	const newParams = new URLSearchParams(searchParams)
	const value = newParams.get("searchPatient")
	const providerCategories =
		categoriesData?.services &&
		categoriesData?.services.find(
			(categorys: CategoriesResponseType) => paramsData.id === categorys.id,
		)

	React.useEffect(() => {
		doctorId == "undefined" && searchParams.delete("doctorId")
		return navigate("?status=active")
	}, [])

	const doctorListOptions = doctorList.data.map((person) => ({
		value: person?.saleClientDoctor?.id,
		label: "Dr." + person?.saleClientDoctor?.firstName + " " + person?.saleClientDoctor?.lastName,
	}))

	const openCompleteTask = () => {
		taskCompletionStore.landingPageTasks.length >= 1
			? taskCompletionStore.setDialogOpen(true)
			: null
	}

	const setSearch = (search: string) => {
		newParams.delete("status")
		newParams.set("searchPatient", search.trim())
		setSearchParams(newParams)
		if (search.trim().length == 0) {
			const newParameter = new URLSearchParams(window.location.search)
			newParameter.delete("searchPatient")
			if (newParameter.size == 0) {
				newParameter.set("status", "active")
			}
			const newUrl = `${window.location.pathname}?${newParameter.toString()}`
			return navigate(newUrl)
		}
	}

	const searchDoctorSales = (doctorIdd: string) => {
		if (!doctorIdd) return
		new URLSearchParams(searchParams)
		newParams.set("doctorId", doctorIdd)
		setSearchParams(newParams)
	}

	React.useEffect(() => {
		setSelectedCategory(category)
		if (!category) {
			setSelectedCategoryName(null)
		}
	}, [category])

	const getSelectedCategoryCount = () => {
		if (selectedCategory) {
			const categoryCount = serviceTreatmentCounts?.data.countForAdminOperator.find(
				(x) => x.providerCategoryId === selectedCategory,
			)
			return categoryCount ? categoryCount.record_count : 0
		}
		return serviceTreatmentCounts?.data?.actionNeedTasks || 0
	}

	const selectedCategoryCount = getSelectedCategoryCount()

	return (
		<React.Suspense>
			<nav style={{ backgroundColor: "#d9d9d9" }}>
				<div className="d-flex align-items-center justify-content-between p-2">
					<div className="d-flex gap-2">
						<SearchInput callback={(search: string) => setSearch(search)} />
						<CheckSales>
							<div style={{ width: "12rem" }}>
								<Select
									options={doctorListOptions}
									isSearchable={true}
									defaultValue={doctorListOptions.find((option) => option.value === doctorId)}
									styles={{
										control: (baseStyles) => ({
											...baseStyles,
											borderRadius: "7px",
										}),
									}}
									placeholder="Select Doctor"
									isClearable={true}
									onChange={(e) => searchDoctorSales(e?.value + "")}
								/>
							</div>
						</CheckSales>
					</div>
					<span className="d-flex justify-content-end text-end mx-2">
						<CheckGenericPermission genericLabel={"Task Completion"}>
							<a href="#TaskCompletion" className="btn btn-primary me-2" onClick={openCompleteTask}>
								Complete Task
							</a>
						</CheckGenericPermission>
						<CheckGenericPermission genericLabel={"Patient Create"}>
							<Button
								href="#PatientInfo"
								variant="primary"
								className="me-2"
								onClick={() => {
									patientStore.setCreateOpen(true)
									patientStore.setIsPatientDatainProcess(true)
								}}
								disabled={patientStore.isPatientDatainProcess}
							>
								{patientStore.isPatientDatainProcess ? (
									<>
										<span
											className="me-2 spinner-border spinner-border-sm"
											role="status"
											aria-hidden="true"
										></span>
										Creating Patient...
									</>
								) : (
									"New Patient"
								)}
							</Button>
						</CheckGenericPermission>
					</span>
				</div>
				<Alert
					className="p-2"
					variant="warning"
					show={patientStore.isPatientDatainProcess && !patientStore.createOpen}
					style={{
						width: "-webkit-fill-available",
						display: "grid",
						marginRight: "1.5rem !important",
						marginInline: "1%",
						marginInlineEnd: "2%",
						padding: "1%",
						backgroundColor: "white",
						borderColor: "white",
						color: "black",
					}}
				>
					{
						<>
							<div>Patient creation is being processed...</div>
							<ProgressBar
								style={{ height: "2px" }}
								now={sessionStore.showToast.uploadPercentage ?? 60}
							/>
						</>
					}
				</Alert>
				<Navbar expand="lg" className="p-0">
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<div className="text-start w-100" style={{ backgroundColor: "#d9d9d9" }}>
							<div className="w-100">
								<Nav variant="tabs" className="m-0 p-0 w-100" style={{ border: "none" }}>
									<Nav.Link
										className="nav-link"
										active={value !== null}
										eventKey={"?searchPatient"}
										as={Link}
										hidden={newParams.get("searchPatient")! == null}
										to={`?searchPatient=${newParams.get("searchPatient")}`}
									>
										Search{" "}
										<Badge
											bg="light"
											style={{ color: "grey", marginLeft: "8px" }}
											pill
											className="text-end countPill"
										>
											{tableData}
										</Badge>
									</Nav.Link>

									<CheckAdmin>
										<Dropdown
											title="All"
											id="basic-nav-dropdown"
											as={ButtonGroup}
											style={{
												border: "none",
												backgroundColor: status === "active" || category ? "white" : "transparent",
												borderRadius: status === "active" || category ? "10px 10px 0 0" : "0",
											}}
										>
											<Nav.Link
												active={status == "active"}
												as={Link}
												to={"?status=active"}
												className="border-0"
											>
												{selectedCategoryName ? selectedCategoryName : "All"}
												<Badge
													bg="light"
													style={{ color: "grey", marginLeft: "8px" }}
													pill
													className="text-end countPill"
												>
													{selectedCategoryCount}
												</Badge>
											</Nav.Link>
											<Dropdown.Toggle split variant="Secondary" id="dropdown-split-basic" />
											<Dropdown.Menu>
												<NavDropdown.Item
													className="d-flex btn border-0 justify-content-between gap-2"
													active={!category}
													as={Link}
													to={"?status=active&"}
													onClick={() => {
														setSelectedCategory(null)
														setSelectedCategoryName("All")
													}}
												>
													All
													<Badge
														bg="secondary"
														style={{ color: "white", marginLeft: "8px" }}
														pill
														className="text-end countPill"
													>
														{serviceTreatmentCounts?.data?.countForAdminOperator.reduce(
															(total, index) => total + (parseInt(index.record_count) || 0),
															0,
														)}
													</Badge>
												</NavDropdown.Item>

												{providerCategories?.categories.map((cat: CategoriesType) => {
													if (cat.type == TaskCategory.OTHERS) return null
													return (
														<NavDropdown.Item
															className="d-flex btn border-0 justify-content-between gap-2"
															active={category == cat.id}
															as={Link}
															to={"?status=active&" + cat.type + "=" + cat.id}
															onClick={() => {
																setSelectedCategory(cat.id)
																setSelectedCategoryName(cat.name)
															}}
														>
															{cat.name}
															<Badge
																bg="secondary"
																style={{ color: "white", marginLeft: "8px" }}
																pill
																className="text-end countPill"
															>
																{
																	serviceTreatmentCounts?.data?.countForAdminOperator.find(
																		(x) => x.providerCategoryId == cat.id,
																	)?.record_count
																}
															</Badge>
														</NavDropdown.Item>
													)
												})}
											</Dropdown.Menu>
										</Dropdown>
									</CheckAdmin>

									<CheckOperator>
										<Dropdown
											title="All"
											id="basic-nav-dropdown"
											as={ButtonGroup}
											className="border-0"
											style={{
												border: "none",
												backgroundColor: status === "active" || category ? "white" : "transparent",
												borderRadius: status === "active" || category ? "10px 10px 0 0" : "0",
											}}
										>
											<Nav.Link
												active={status == "active"}
												as={Link}
												to={"?status=active"}
												className="border-0"
											>
												{selectedCategoryName ? selectedCategoryName : "Action Needed"}
												<Badge
													bg="light"
													style={{ color: "grey", marginLeft: "8px" }}
													pill
													className="text-end countPill"
												>
													{selectedCategoryCount}
												</Badge>
											</Nav.Link>
											<Dropdown.Toggle split variant="Secondary" id="dropdown-split-basic" />
											<Dropdown.Menu>
												<NavDropdown.Item
													className="d-flex btn border-0 justify-content-between gap-2"
													active={!category}
													as={Link}
													to={"?status=active&"}
													onClick={() => {
														setSelectedCategory(null)
														setSelectedCategoryName("Action Needed")
													}}
												>
													All
													<Badge
														bg="secondary"
														style={{ color: "white", marginLeft: "8px" }}
														pill
														className="text-end countPill"
													>
														{serviceTreatmentCounts?.data?.actionNeedTasks}
													</Badge>
												</NavDropdown.Item>

												{providerCategories?.categories.map((cat: CategoriesType) => {
													if (cat.type == TaskCategory.OTHERS) return null
													return (
														<NavDropdown.Item
															className="d-flex btn border-0 justify-content-between gap-2"
															active={category == cat.id}
															as={Link}
															to={"?status=active&" + cat.type + "=" + cat.id}
															onClick={() => {
																setSelectedCategory(cat.id)
																setSelectedCategoryName(cat.name)
															}}
														>
															{cat.name}
															<Badge
																bg="secondary"
																style={{ color: "white", marginLeft: "8px" }}
																pill
																className="text-end countPill"
															>
																{
																	serviceTreatmentCounts?.data?.countForAdminOperator.find(
																		(x) => x.providerCategoryId == cat.id,
																	)?.record_count
																}
															</Badge>
														</NavDropdown.Item>
													)
												})}
											</Dropdown.Menu>
										</Dropdown>
									</CheckOperator>

									<CheckDoctor>
										<Nav.Link
											className="nav-link"
											active={status == "active"}
											as={Link}
											to={`?status=active`}
										>
											Action Needed
											<Badge
												bg="light"
												style={{ color: "grey", marginLeft: "8px" }}
												pill
												className="text-end countPill"
											>
												{serviceTreatmentCounts?.data?.actionNeedTasks}
											</Badge>
										</Nav.Link>
									</CheckDoctor>

									<CheckSales>
										<Nav.Link
											className="nav-link"
											active={status == "active"}
											as={Link}
											to={`?status=active${doctorId != "undefined" && doctorId ? "&doctorId=" + doctorId : ""}`}
										>
											Action Needed
											<Badge
												bg="light"
												style={{ color: "grey", marginLeft: "8px" }}
												pill
												className="text-end countPill"
											>
												{serviceTreatmentCounts?.data?.actionNeedTasks}
											</Badge>
										</Nav.Link>
									</CheckSales>

									<CheckOperator>
										<Nav.Link
											className="nav-link"
											active={status == "AwaitingDoctor"}
											eventKey={"?status=AwaitingDoctor"}
											as={Link}
											to={`?status=AwaitingDoctor`}
										>
											Awaiting Doctor
											<Badge
												bg="light"
												style={{ color: "grey", marginLeft: "8px" }}
												pill
												className="text-end countPill"
											>
												{serviceTreatmentCounts?.data?.categoriesCount.find((x) => x.categoryId)
													?.count ?? 0}
											</Badge>
										</Nav.Link>
									</CheckOperator>

									<CheckSales>
										{providerCategories?.categories.map((cat: CategoriesType) => {
											if (cat.type !== "consume") return null
											return (
												<Nav.Link
													className="nav-link"
													active={categoryconsume == cat.id}
													eventKey={"?" + cat.type + "=" + cat.id}
													as={Link}
													to={`?${cat.type}=${cat.id}${doctorId != "undefined" && doctorId ? "&doctorId=" + doctorId : ""}`}
													key={cat.id}
												>
													{cat.name}

													<Badge
														bg="light"
														style={{ color: "grey", marginLeft: "8px" }}
														pill
														className="text-end countPill"
													>
														{selectedCategoryCount}
													</Badge>
												</Nav.Link>
											)
										})}
									</CheckSales>

									<CheckDoctor>
										{providerCategories?.categories.map((cat: CategoriesType) => {
											if (cat.type !== "consume") return null
											return (
												<Nav.Link
													className="nav-link"
													active={categoryconsume == cat.id}
													eventKey={"?" + cat.type + "=" + cat.id}
													as={Link}
													to={`?${cat.type}=${cat.id}`}
													key={cat.id}
												>
													{cat.name}
													<Badge
														bg="light"
														style={{ color: "grey", marginLeft: "8px" }}
														pill
														className="text-end countPill"
													>
														{serviceTreatmentCounts?.data?.categoriesCount.find(
															(x) => x.categoryId == cat.id,
														)?.count ?? 0}
													</Badge>
												</Nav.Link>
											)
										})}
									</CheckDoctor>

									<Nav.Link
										className="nav-link"
										active={status == "paused"}
										eventKey={"?status=paused"}
										as={Link}
										to={`?status=paused${doctorId != "undefined" && doctorId ? "&doctorId=" + doctorId : ""}`}
									>
										Paused{" "}
										<Badge
											bg="light"
											style={{ color: "grey", marginLeft: "8px" }}
											pill
											className="text-end countPill"
										>
											{serviceTreatmentCounts?.data?.completedPauseArchiveCount.find(
												(x) => x.status == "paused",
											)?.count ?? 0}
										</Badge>
									</Nav.Link>

									<Nav.Link
										className="nav-link"
										active={status == "archived"}
										eventKey={"?status=archived"}
										as={Link}
										to={`?status=archived${doctorId != "undefined" && doctorId ? "&doctorId=" + doctorId : ""}`}
									>
										Archived{" "}
										<Badge
											bg="light"
											style={{ color: "grey", marginLeft: "8px" }}
											pill
											className="text-end countPill"
										>
											{(serviceTreatmentCounts?.data?.completedPauseArchiveCount.find(
												(x) => x.status == "archived",
											)?.count ?? 0) +
												(serviceTreatmentCounts?.data?.completedPauseArchiveCount.find(
													(x) => x.status == "active_archive",
												)?.count ?? 0)}
										</Badge>
									</Nav.Link>

									<Nav.Link
										className="nav-link"
										active={status == "completed"}
										eventKey={"?status=completed"}
										as={Link}
										to={`?status=completed${doctorId != "undefined" && doctorId ? "&doctorId=" + doctorId : ""}`}
									>
										Shipped{" "}
										<Badge
											bg="light"
											style={{ color: "grey", background: "red !important", marginLeft: "8px" }}
											pill
											className="text-end countPill"
										>
											{serviceTreatmentCounts?.data?.completedPauseArchiveCount.find(
												(x) => x.status == "completed",
											)?.count ?? 0}
										</Badge>
									</Nav.Link>
								</Nav>
							</div>
						</div>
					</Navbar.Collapse>
				</Navbar>
			</nav>
		</React.Suspense>
	)
}

export default SubNavBar
