import { create } from "zustand"
import { AddressSchemaType } from "../services/CelebDoctorAddress.Service"

type EditId = string | null

interface ProfileDialogState {
	modalOpen: boolean
	setModalOpen: (next: boolean) => void

	editOpen: AddressSchemaType | null
	setEditOpen: (next: AddressSchemaType | null) => void

	selectedDoctorId: string | null
	setSelectedDoctorId: (next: string | null) => void

	openCreditCardPreference: string | null | undefined
	setOpenCreditCardPreference: (next: string | null | undefined) => void

	createOpen: boolean
	setCreateOpen: (next: boolean) => void

	typesEditOpen: EditId
	setTypesEditOpen: (next: EditId) => void

	paymentCreateOpen: boolean
	setPaymentCreateOpen: (next: boolean) => void

	activeTabSession: number | string
	setActiveTabSession: (next: number | string) => void

	onCloseCallback: (() => void) | null
	setOnCloseCallback: (callback: (() => void) | null) => void
}

export const useProfileDialogStore = create<ProfileDialogState>()((set) => ({
	modalOpen: false,
	setModalOpen: (next: boolean) => set({ modalOpen: next }),
	editOpen: null,
	setEditOpen: (next: AddressSchemaType | null) => set({ editOpen: next }),
	selectedDoctorId: null,
	setSelectedDoctorId: (next: string | null) => set({ selectedDoctorId: next }),
	openCreditCardPreference: null,
	setOpenCreditCardPreference: (next: string | null | undefined) =>
		set({ openCreditCardPreference: next }),
	createOpen: false,
	setCreateOpen: (next: boolean) => set({ createOpen: next }),
	typesEditOpen: null,
	setTypesEditOpen: (next: EditId) => set({ typesEditOpen: next }),
	paymentCreateOpen: false,
	setPaymentCreateOpen: (next: boolean) => set({ paymentCreateOpen: next }),
	activeTabSession: 0,
	setActiveTabSession: (next) => set({ activeTabSession: next }),
	onCloseCallback: null,
	setOnCloseCallback: (callback) => set({ onCloseCallback: callback }),
}))
