import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { Suspense } from "react"
import { Button, Col, Form, Row, CloseButton } from "react-bootstrap"
import { useFormContext } from "react-hook-form"
import Select from "react-select"
import MoonLoader from "react-spinners/MoonLoader"
import {
	AddressSchemaType,
	useGetDoctorAddressesMutate,
} from "../../../services/CelebDoctorAddress.Service"
import { PatientSchemaType } from "../../../services/CelebPatient.Service"
import { getPatientByIdQuery } from "../../../services/CelebTreatment.Service"
import { useDoctorListQuery, useGetAllSubUsers } from "../../../services/CelebUser.Service"
import { fileUploadingType, usePatientDialogStore } from "../../../store/patientDailogStore"
import { userSessionStore } from "../../../store/userSessionStore"
import { PatientEthenicities } from "../../../utils/appConstants"
import { dateFormater } from "../replaceOrder-v2/PatientInfo.tab"

import PatientInputForm, {
	PatientDateForm,
	PatientOtherForm,
	PatientSelectForm,
} from "./PatientFormFields"
import { getTreatmentFilesByCategoryQuery } from "../../../services/CelebTreatmentFiles.Service"
import { fileResult } from "./PatientPhotos.tab"
import { constants } from "../../../services/CelebApiConstants"
import patientProfileIcon from "../../../assets/img/patient-profile-icon.png"

const PatientInfoTab = () => {
	return (
		<React.Suspense
			fallback={
				<MoonLoader
					speedMultiplier={4}
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
					}}
				/>
			}
		>
			<PatientInfoImpl />
		</React.Suspense>
	)
}

const PatientInfoImpl = () => {
	const methods = useFormContext<PatientSchemaType>()
	const { mutateAsync: getDoctorAddressById } = useGetDoctorAddressesMutate()
	useDoctorListQuery()
	const { setAllOffices, allOffices, doctorList, customErrors, profilePicture, setProfilePicture } =
		usePatientDialogStore()
	const sessionUser = userSessionStore((state: any) => state.user)
	const { data: subUsers } = useGetAllSubUsers()
	const PatientDialog = usePatientDialogStore()
	const { data: defaultValuesEdit } = getPatientByIdQuery(PatientDialog.typesEditOpen)
	const imageInputId = "file-upload"
	const [isClear, setIsClear] = React.useState(false)

	const { data: defaultTreatmentFiles } = getTreatmentFilesByCategoryQuery(
		PatientDialog.typesEditOpen,
		["patient"],
	)

	React.useEffect(() => {
		formEditPopulate()
	}, [])

	const profilePhotoURI: fileResult = defaultTreatmentFiles.find(
		(file: fileResult) => file.fileCategory === "patient_profile_photo",
	)

	const formEditPopulate = () => {
		methods.setValue("selectedOffice", defaultValuesEdit.treatmentPlanDoctorAddressId)
		const dob = new Date(defaultValuesEdit.patient.dob)
		const setDate: HTMLInputElement | null = document.getElementById("date") as HTMLInputElement
		if (setDate) {
			setDate.value = dateFormater(dob)
		}
		getDoctorAddress({ label: "", value: sessionUser.id })
	}

	const getDoctorAddress = async (e: { label: string; value: string }) => {
		const data = await getDoctorAddressById(e.value)
		methods.setValue("assignedDoctor", e.value)
		methods.setValue("doctorsWithAccess", [e.value])
		setAllOffices(data)
	}

	const changeProfilePicture = (image: FileList | null) => {
		if (image?.length) {
			const file = image[0]
			const fileData: fileUploadingType = {
				file: file,
				additionalInfo: {
					note: "new image",
				},
			}
			setProfilePicture(fileData)
		}
	}

	const clearProfilePicture = () => {
		setProfilePicture({
			file: new File([], ""),
			additionalInfo: {
				note: "delete this image",
			},
		})
		setIsClear(true)
	}

	const handleVisibilityChange = (selectedOptions: any) => {
		const dataSet = methods.watch("doctorsWithAccess")
		const selectedDoctor = selectedOptions.map((x: any) => x.value)
		if (dataSet != undefined) {
			methods.setValue("treatmentPlan.doctorsWithAccess", [...dataSet, ...selectedDoctor])
		} else {
			methods.setValue("treatmentPlan.doctorsWithAccess", [...selectedDoctor])
		}
	}

	return (
		<React.Fragment>
			<Form onSubmit={methods.handleSubmit((x) => console.warn(x))}>
				<Col>
					<Row className="p-2">
						<Col>
							<PatientInputForm name="firstName" required label="First Name" />
						</Col>
						<Col>
							<PatientInputForm name="lastName" required label="Last Name" />
						</Col>

						<Col className="d-flex justify-content-center">
							<div className="d-flex flex-column align-items-center" style={{ width: "7.5rem" }}>
								<div
									className="card text-bg-white mb-2 position-relative"
									style={{
										width: "6rem",
										height: "6rem",
										border: "none",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<Suspense fallback={<img src={patientProfileIcon} className="img-fluid" />}>
										<img
											onClick={() => document.getElementById(imageInputId)?.click()}
											src={
												profilePicture?.file
													? URL.createObjectURL(profilePicture?.file)
													: constants.IMAGEURL + profilePhotoURI?.fileUri
											}
											onError={(e) => (e.currentTarget.src = patientProfileIcon)}
											className="img-fluid"
											alt="Upload Icon"
											style={{
												width: "100%",
												height: "100%",
												objectFit: "contain",
												borderRadius: "10px",
												cursor: "pointer",
												backgroundColor: "#f0ecec",
											}}
										/>
									</Suspense>

									<CloseButton
										onClick={clearProfilePicture}
										style={{
											position: "absolute",
											top: "-5px",
											right: "-5px",
											backgroundColor: "#E3E0F0",
											borderRadius: "50%",
											zIndex: 2,
										}}
									/>

									<input
										type="file"
										accept="image/*"
										hidden
										id={imageInputId}
										onChange={(e) => changeProfilePicture(e.currentTarget.files)}
									/>
								</div>
								<Button
									className="d-block p-0 text-center"
									style={{
										background: "#E3E0F0",
										width: "5rem",
										height: "30px",
										borderRadius: "10px",
										alignContent: "center",
									}}
									onClick={() => document.getElementById(imageInputId)?.click()}
								>
									Profile
								</Button>
							</div>
						</Col>
					</Row>

					<Row className="p-2">
						<Col>
							<PatientInputForm name="phone" label="Phone Number" type="number" />
						</Col>
						<Col>
							<PatientSelectForm name="gender" required label="Gender">
								<option value={""}>Select Gender</option>
								<option value={"male"}>Male</option>
								<option value={"female"}>Female</option>
								<option value={"other"}>Other</option>
							</PatientSelectForm>
						</Col>
						<Col>
							<PatientSelectForm name="ethenicity" label="Ethnicity">
								<option disabled>Select Ethnicity</option>
								{Object.keys(PatientEthenicities).map((ethenicity) => {
									return (
										<option key={ethenicity} value={ethenicity}>
											{PatientEthenicities[ethenicity]}
										</option>
									)
								})}
							</PatientSelectForm>
						</Col>
					</Row>
					<div className="d-flex p-2 w-100 gap-4">
						<Col className="dateCol">
							<PatientDateForm
								name="dob"
								defaultValue={methods.watch("dob")}
								autoFocus
								required
								label="Date of Birth"
							/>
						</Col>
						<div className="w-100">
							<PatientInputForm name="email" label="Patient Email Address" type="email" />
							<p className="text-danger">{customErrors.email}</p>
						</div>
						<div className="w-100">
							<PatientSelectForm name="selectedOffice" required label="Select Office">
								<option value={defaultValuesEdit.treatmentPlanDoctorAddressId}>
									{defaultValuesEdit.treatmentPlanDoctorAddress.name}
								</option>
								{allOffices
									.filter((office) => office.id !== defaultValuesEdit.treatmentPlanDoctorAddressId)
									.map((office: AddressSchemaType) => {
										return <option value={office.id}>{office.name}</option>
									})}
							</PatientSelectForm>
						</div>
					</div>
					<div className="p-2" style={{ width: "33%" }}>
						{sessionUser.isAdmin && (
							<div className="w-100">
								<PatientOtherForm name="assignedDoctor" label="Doctor to Assign" required>
									<Select
										onChange={(e: any) => {
											getDoctorAddress(e)
										}}
										options={doctorList}
									/>
								</PatientOtherForm>
							</div>
						)}
					</div>
					{subUsers.data.length >= 1 && (
						<div className="w-25 p-2">
							<Form.Group>
								<Form.Label>Visibility</Form.Label>
								<Select
									isMulti
									options={subUsers?.data?.map((subUser: any) => ({
										value: subUser.id,
										label: `${subUser.firstName} ${subUser.lastName}`,
									}))}
									onChange={handleVisibilityChange}
									value={subUsers?.data?.map((subUser: any) => {
										if (
											methods
												.watch("treatmentPlan.doctorsWithAccess")
												?.find((z: any) => z == subUser.id)
										) {
											return {
												value: subUser.id,
												label: `${subUser.firstName} ${subUser.lastName}`,
											}
										}
									})}
									isSearchable
									isClearable
								/>
							</Form.Group>
						</div>
					)}
				</Col>
			</Form>
			<footer className="d-flex m-3 position-absolute bottom-0 end-0">
				<Button className="m-0" onClick={(e) => (window.location.hash = "#Scan")}>
					<FontAwesomeIcon
						icon={faChevronRight}
						style={{ objectFit: "contain", width: "1rem", height: "1rem" }}
						className="m-0 p-0"
					/>
				</Button>
			</footer>
		</React.Fragment>
	)
}

export default PatientInfoTab
