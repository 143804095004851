import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import {
	Badge,
	Button,
	Dropdown,
	DropdownButton,
	Form,
	OverlayTrigger,
	Popover,
	Table,
} from "react-bootstrap"
import Moment from "react-moment"
import { Params, useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import { useTreatmentStatusMutation } from "../../services/CelebTreatment.Service"
import {
	TreatmentSchemaType,
	useTaskServicesQuery,
} from "../../services/CelebTreatmentTask.Service"
import CompletedTableSkelton from "../../skelton-components/completedTableSkelton"
import { useCommonStore } from "../../store/commonStore"
import { usePatientDialogStore } from "../../store/patientDailogStore"
import { useSidebarStore } from "../../store/sideBarStore"
import { useTaskCompletionDialogStore } from "../../store/taskCompletionDialogStore"
import { userSessionStore } from "../../store/userSessionStore"
import { TreatmentStates } from "../../utils/appConstants"
import { CheckAdmin, CheckGenericPermission } from "../permissions/CheckPermision"
import { infoMapperNames } from "../utlity/GenricFunctions"
import SortButton from "../utlity/SortButton"
import CustomPagination from "../utlity/pagination-component"
import { constants } from "../../services/CelebApiConstants"
import { Avatar } from "@mui/material"
import ShowReplaceOrderTable from "./dashbordComponents/showReplaceOrderTable"
import { getButtonProps } from "./utils/dashboardHelpers"

const TasksTable = () => {
	return (
		<React.Suspense>
			<LandingPageImpl />
		</React.Suspense>
	)
}

const LandingPageImpl = () => {
	const paramsData: Readonly<Params<string>> = useParams()
	const [searchParams, setSearchParams] = useSearchParams()
	const newParams = new URLSearchParams(searchParams)
	const tasksStore = useTaskCompletionDialogStore()
	const patientStore = usePatientDialogStore()
	const [currentPage, setCurrentPage] = React.useState<number>(0)
	const [sortingData, setSortingData] = React.useState<string[] | null>([
		"treatmentPlanPatient",
		"createdAt",
		"desc",
	])
	const status = searchParams.get("status")
	const consumerCategory = searchParams.get("consume")
	const provideCategory = searchParams.get("provide")
	const searchPatient = searchParams.get("searchPatient")
	const doctorIdParam = searchParams.get("doctorId")
	const PatientIdSearch = searchParams.get("PatientId")
	const sideBarStore = useSidebarStore()
	const { user } = userSessionStore()
	const { mutateAsync: updateTaskStatus } = useTreatmentStatusMutation()
	const { ReplaceOrderDetails, setReplaceOrderDetails, setOpenCustomPricing } = useCommonStore()
	const { data: tableData } = useTaskServicesQuery({
		path:
			status == "active" && provideCategory
				? "me"
				: provideCategory || consumerCategory || status == "AwaitingDoctor"
					? "awaiting"
					: "me",
		dataProps: {
			search: searchPatient || "",
			limit: "10",
			offset: currentPage + "",
			serviceId: paramsData.id ? paramsData.id : "",
			status: status || "active",
			...(doctorIdParam && { ["doctorId"]: doctorIdParam }),
			...(consumerCategory && { ["consumerCategory"]: consumerCategory }),
			...(provideCategory && { ["providerCategory"]: provideCategory }),
			...(sortingData && {
				["sortingParams"]: JSON.stringify(sortingData),
			}),
		},
	})

	const taskStoreChange = (isTaskChecked: boolean, task: TreatmentSchemaType) => {
		if (isTaskChecked) return tasksStore.setLandingPageTasks(task)
		const updatedTask: TreatmentSchemaType[] = tasksStore.landingPageTasks.filter(
			(x: TreatmentSchemaType) => {
				return x.id !== task.id
			},
		)
		tasksStore.updateLandingPageTasks(updatedTask)
	}

	const onTreatmentStateChange = async (
		patientID: TreatmentSchemaType,
		statusLocal: TreatmentStates,
	) => {
		const id = patientID.treatmentId
		id && updateTaskStatus({ treatmentId: id, status: statusLocal })
	}

	const addNewQueryParam = (patientID: TreatmentSchemaType) => {
		if (
			ReplaceOrderDetails?.ReplaceOrderMark &&
			ReplaceOrderDetails?.PatientId == patientID.patientId
		) {
			return
		}
		const id = patientID.treatmentId
		id && newParams.set("PatientId", id)
		sideBarStore.setTimelineData(patientID)
		if (!newParams.get("status") && !newParams.get("consume")) {
			newParams.set("status", "active")
		}
		setSearchParams(newParams)

		//this is for replace order state
		if (ReplaceOrderDetails?.PatientId !== patientID?.patientId) {
			setReplaceOrderDetails(null)
		}
	}

	const onEdit = async (dataOfTable: TreatmentSchemaType) => {
		if (dataOfTable.treatmentPlanServiceTaskTreatment.isReplacementOrder) {
			return patientStore.setReplaceOpen({ id: dataOfTable.treatmentId, isReplacementNew: false })
		}
		PatientIdSearch && patientStore.setTypesEditOpen(PatientIdSearch)
	}

	const onTransfer = async (patientID: TreatmentSchemaType) => {
		const id = patientID.treatmentId
		id && patientStore.setTransferPatientId(id)
		patientStore.setTransferDialogOpen(patientID)
	}

	const onCheckTpStatus = async (patientID: TreatmentSchemaType) => {
		const id = patientID.treatmentId
		patientStore.setCheckTpStatusForTreatment(id)
	}

	React.useEffect(() => {
		tableData?.treatment[0] && addNewQueryParam(tableData?.treatment[0])
		setReplaceOrderDetails(null)
	}, [tableData?.treatment[0], status])

	const renderPopover = (props: any, x: TreatmentSchemaType) => (
		<Popover id="popover-basic" {...props}>
			<ShowReplaceOrderTable activeOrder={x.treatmentId} patientId={x.patientId} />
		</Popover>
	)

	const handleSortChange = (apiParams: string[], sortOrder: "asc" | "desc" | undefined): void => {
		const updatedSortingData = sortOrder ? [...apiParams, sortOrder] : null
		setSortingData(updatedSortingData ?? ["treatmentPlanPatient", "createdAt", "desc"])
	}

	const selectAllTreatments = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		if (e.currentTarget.checked == false) return tasksStore.updateLandingPageTasks([])
		tableData?.treatment.forEach((element: TreatmentSchemaType) => {
			taskStoreChange(e.currentTarget.checked, element)
		})
	}

	return (
		<React.Suspense fallback={<CompletedTableSkelton />}>
			<div
				style={{
					display: "flex",
					flex: 1,
					flexDirection: "column",
					height: "calc(90vh - 100px)",
				}}
			>
				<section
					style={{
						flex: 1,
						overflow: "auto",
					}}
				>
					<Table striped hover>
						<thead>
							<tr className="table-header" style={{ textWrap: "nowrap" }}>
								{status == "active" && !user.isDoctor && (
									<th className="d-flex gap-1">
										Check
										{status == "active" && (
											<CheckGenericPermission genericLabel="Task Select">
												<Form.Check
													checked={tasksStore?.landingPageTasks.length > 0}
													onClick={(e) => {
														selectAllTreatments(e)
													}}
												></Form.Check>
											</CheckGenericPermission>
										)}
									</th>
								)}

								<th style={{ textAlign: "start" }}></th>
								<th style={{ textAlign: "start" }}>
									Patient Name{" "}
									<SortButton
										sortKey="treatmentPlanPatient#firstName"
										onSortChange={handleSortChange}
									/>
								</th>
								{!user.isDoctor && (
									<th style={{ textAlign: "start" }} hidden={status != "active"}>
										Task
										<SortButton
											sortKey="treatmentPlanServiceTask#name"
											onSortChange={handleSortChange}
										/>
									</th>
								)}
								<th style={{ textAlign: "start" }}>
									Patient ID{" "}
									<SortButton
										sortKey="treatmentPlanPatient#entityId"
										onSortChange={handleSortChange}
									/>
								</th>
								<th style={{ textAlign: "start" }}>
									Submit Date{" "}
									<SortButton
										sortKey="treatmentPlanPatient#createdAt"
										onSortChange={handleSortChange}
									/>
								</th>
								<th style={{ textAlign: "start" }}>
									Clinic{" "}
									<SortButton
										sortKey="treatmentPlanServiceTaskTreatment#treatmentPlanDoctorAddress#name"
										onSortChange={handleSortChange}
									/>
								</th>
								<th style={{ textAlign: "start" }}>
									Doctor{" "}
									<SortButton
										sortKey="treatmentPlanDoctor#firstName"
										onSortChange={handleSortChange}
									/>
								</th>
								<th style={{ textAlign: "start" }}>Action Buttons</th>
							</tr>
						</thead>
						<tbody>
							{tableData ? (
								tableData?.treatment?.map((x: TreatmentSchemaType) => {
									return (
										<>
											<tr
												className="table-data"
												data-Active={
													newParams.get("PatientId") === x.treatmentId ||
													x.patientId === ReplaceOrderDetails?.PatientId
												}
												onClick={() => {
													addNewQueryParam(x)
												}}
												style={{ verticalAlign: "middle" }}
											>
												{status == "active" && !user.isDoctor && (
													<td onClick={(event) => event?.stopPropagation()}>
														<span className="d-flex gap-2 ms-5">
															{status == "active" && (
																<CheckGenericPermission genericLabel="Task Select">
																	<Form.Check
																		checked={
																			tasksStore?.landingPageTasks.find((z) => z.id === x.id)
																				? true
																				: false
																		}
																		onClick={(e) => {
																			taskStoreChange(e.currentTarget.checked, x)
																		}}
																	></Form.Check>
																</CheckGenericPermission>
															)}
														</span>
													</td>
												)}

												<td style={{ textAlign: "start" }}>
													{x.treatmentPlanServiceTaskTreatment?.fileTreatmentPlanId?.fileUri && (
														<Avatar
															src={
																constants.IMAGEURL +
																x.treatmentPlanServiceTaskTreatment?.fileTreatmentPlanId?.fileUri
															}
															variant="circular"
														/>
													)}
												</td>

												<td style={{ textAlign: "start" }}>
													<div className="d-flex align-items-center justify-content-between gap-2">
														<CheckGenericPermission genericLabel="Patient Name">
															{" " +
																infoMapperNames(x.treatmentPlanPatient, ["entityId", "createdAt"])}
														</CheckGenericPermission>
														<div
															className="d-flex align-items-center justify-content-end gap-2"
															style={{ minWidth: "3rem" }}
														>
															<span style={{ minWidth: "30px" }}>
																<Badge bg="secondary" pill className="text-end">
																	{ReplaceOrderDetails?.PatientId == x.patientId &&
																		ReplaceOrderDetails?.ReplaceOrderMark}
																</Badge>
															</span>

															{x.treatmentPlanServiceTaskTreatment.isReplacementOrder && (
																<OverlayTrigger
																	trigger="click"
																	placement="bottom"
																	rootClose
																	rootCloseEvent="click"
																	overlay={(props) => renderPopover(props, x)}
																>
																	<FontAwesomeIcon
																		icon={faChevronDown}
																		style={{ cursor: "pointer", color: "#8faadc", zIndex: "1000" }}
																	/>
																</OverlayTrigger>
															)}
														</div>
													</div>
												</td>

												{!user.isDoctor && (
													<td style={{ textAlign: "start" }} hidden={status != "active"}>
														{x?.treatmentPlanServiceTask?.name}
													</td>
												)}

												<td style={{ textAlign: "start" }}>
													<CheckGenericPermission genericLabel="Patient ID">
														{x.treatmentPlanPatient.entityId}
													</CheckGenericPermission>
												</td>

												<td style={{ textAlign: "start" }}>
													{" "}
													<CheckGenericPermission genericLabel="Patient Submit Date">
														{
															<Moment
																date={x.treatmentPlanServiceTaskTreatment.createdAt}
																format="MM/DD/YYYY"
															/>
														}
													</CheckGenericPermission>
												</td>

												<td style={{ textAlign: "start" }}>
													{" "}
													<CheckGenericPermission genericLabel="Patient Clinic">
														{x.treatmentPlanServiceTaskTreatment.treatmentPlanDoctorAddress.na}
													</CheckGenericPermission>
												</td>

												<td style={{ textAlign: "start" }}>
													{" "}
													<CheckGenericPermission genericLabel="Patient Assigned Doctor">
														Dr.{" "}
														{x.treatmentPlanDoctor.firstName + " " + x.treatmentPlanDoctor.lastName}
													</CheckGenericPermission>
												</td>

												<td style={{ textAlign: "start", textWrap: "nowrap" }}>
													<div className="d-flex gap-2">
														<CheckGenericPermission genericLabel={"View TP"}>
															<Button
																disabled={
																	x.treatmentPlanServiceTaskTreatment.editorFilesStatus !==
																		"processed" && user?.isDoctor
																}
																as="a"
																target="_blank"
																href={`/editor/${x.id}?treatmentId=${x.treatmentId}`}
																className={`override-button ${
																	getButtonProps(
																		x.treatmentPlanServiceTaskTreatment.editorFilesStatus,
																	).className
																}`}
																data-status={
																	getButtonProps(
																		x.treatmentPlanServiceTaskTreatment.editorFilesStatus,
																	).text
																}
															>
																<span className="button-text">View TP</span>
																<span className="status-text">
																	{
																		getButtonProps(
																			x.treatmentPlanServiceTaskTreatment.editorFilesStatus,
																		).text
																	}
																</span>
																<span className="loader"></span>
															</Button>
														</CheckGenericPermission>
														<DropdownButton id="dropdown-basic-button" title="">
															<CheckGenericPermission genericLabel={"Patient Replace"}>
																{status == "completed" && (
																	<Dropdown.Item
																		href="#PatientInfo"
																		onClick={(e) => {
																			patientStore.setReplaceOpen({
																				id: x.treatmentId,
																				isReplacementNew: false,
																			})
																		}}
																	>
																		Replace
																	</Dropdown.Item>
																)}
															</CheckGenericPermission>
															<CheckGenericPermission genericLabel={"Patient Edit"}>
																<Dropdown.Item
																	href="#PatientInfo"
																	onClick={() => {
																		onEdit(x)
																	}}
																>
																	Edit
																</Dropdown.Item>
															</CheckGenericPermission>
															<CheckGenericPermission genericLabel={"Patient Pause"}>
																<Dropdown.Item
																	onClick={() => onTreatmentStateChange(x, TreatmentStates.PAUSED)}
																>
																	Pause
																</Dropdown.Item>
															</CheckGenericPermission>
															<CheckGenericPermission genericLabel={"Patient Archive"}>
																<Dropdown.Item
																	onClick={() =>
																		onTreatmentStateChange(x, TreatmentStates.ACTIVE_ARCHIVED)
																	}
																>
																	Archive
																</Dropdown.Item>
															</CheckGenericPermission>
															<CheckGenericPermission genericLabel={"Patient Transfer"}>
																<Dropdown.Item href="#TransferInfo" onClick={() => onTransfer(x)}>
																	Transfer
																</Dropdown.Item>
															</CheckGenericPermission>
															<CheckAdmin>
																<Dropdown.Item href="#TPStatus" onClick={() => onCheckTpStatus(x)}>
																	TP Status
																</Dropdown.Item>
																<Dropdown.Item
																	href="#CustomPricing"
																	onClick={() => setOpenCustomPricing(x.treatmentId)}
																>
																	Pricing
																</Dropdown.Item>
															</CheckAdmin>
														</DropdownButton>
													</div>
												</td>
											</tr>
										</>
									)
								})
							) : (
								<CompletedTableSkelton />
							)}
						</tbody>
					</Table>
				</section>
				<div style={{ marginTop: "auto", padding: "10px 0" }}>
					<CustomPagination
						currentPage={currentPage / 10}
						total={tableData?.total}
						onChange={(pagenumber) => setCurrentPage(pagenumber * 10)}
					/>
				</div>
			</div>
		</React.Suspense>
	)
}

export default TasksTable
