import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query"
import { z } from "zod"
import { queryClient } from "../providers/QueryProvider"
import { constants } from "./CelebApiConstants"
import { CelebRequestGeneratorService } from "./CelebRequestGenerator.Service"

import axios, { AxiosResponse } from "axios"
import { TeethState } from "../store/toothSelectionStore"
import { PatientTransferData } from "./CelebInvoice.Service"
import {
	UseSearchTreatmentMutationProps,
	UseTreatmentServiceQueryProps,
} from "./CelebTreatmentTask.Service"
export class CelebTreatmentPlanService {
	static _instance: CelebTreatmentPlanService = new CelebTreatmentPlanService()

	private constructor() {
		CelebTreatmentPlanService._instance = this
	}

	static getInstance(): CelebTreatmentPlanService {
		return CelebTreatmentPlanService._instance
	}

	async getDetails(treatmentId: string | null) {
		const requestUrl = `${constants.TREATMENT_PLAN}/${treatmentId}`

		return await CelebRequestGeneratorService.processGetRequest(requestUrl)
	}

	async getReplacementOrdersInfo(treatmentId: string | null | undefined) {
		if (!treatmentId) return
		const requestUrl = `${constants.TREATMENT_PLAN}/get/replacement/orders/${treatmentId}`
		return await CelebRequestGeneratorService.processGetRequest(requestUrl)
	}

	async getReplacementOrders(patientID: string | null | undefined) {
		if (!patientID) return
		const requestUrl = `/api/${constants.API_V1}${constants.TREATMENT_PLAN}/${"get/replacement/orders?patientId=" + patientID}`
		return await axios.get<
			AxiosResponse<
				{
					"replacement-number": string
					id: string
					treatmentId: string
					originalTreatmentId: string
				}[]
			>
		>(requestUrl)
	}
	async getTransferedDetails(treatmentId: string | null) {
		const requestUrl = `${constants.TREATMENT_PLAN}${constants.TRANSFERED}/${treatmentId}`
		return await CelebRequestGeneratorService.processGetRequest(requestUrl)
	}

	async updateStatus(
		treatmentId: string,
		status: string | null = null,
		editorFilesStatus: string | null = null,
	) {
		let queryParam = ""
		if (status) {
			queryParam += `?status=${status}`
		}
		if (editorFilesStatus) {
			queryParam += queryParam
				? `&editorFilesStatus=${editorFilesStatus}`
				: `?editorFilesStatus=${editorFilesStatus}`
		}
		const requestUrl = `${constants.TREATMENT_PLAN}/status/${treatmentId}${queryParam}`
		return await CelebRequestGeneratorService.processPutRequest(requestUrl, null)
	}

	async checkTPStatus(treatmentId: string | null) {
		const requestUrl = `${constants.BASE_URL}/3dFeature/reluFeature/getReluJobStatus?jobId=${treatmentId}`
		return await CelebRequestGeneratorService.processBaseGetRequest(requestUrl)
	}
	async searchTreatments(queryParams: UseSearchTreatmentMutationProps) {
		const queryStringArray: string[] = []
		Object.keys(queryParams).forEach((data) => {
			queryStringArray.push(data + "=" + queryParams[data])
		})

		const queryString = "?" + queryStringArray.join("&")
		const requestUrl = `${constants.TREATMENT_TASKS}/search/${queryString}`
		return await CelebRequestGeneratorService.processGetRequest(requestUrl)
	}

	async getDoctorTreatments(queryParams: UseTreatmentServiceQueryProps) {
		const queryStringArray: string[] = []
		Object.keys(queryParams).forEach((data) => {
			queryStringArray.push(data + "=" + queryParams[data])
		})

		const queryString = "?" + queryStringArray.join("&")
		const requestUrl = `${constants.TREATMENT_PLAN}/me${queryString}`

		return await CelebRequestGeneratorService.processGetRequest(requestUrl)
	}
}

export const editMutationListFn = (props?: UseStatusUpdateQueryProps) =>
	["treatment", props].filter(Boolean)

export const useTreatmentStatusMutation = () =>
	useMutation({
		mutationFn: (statusUpdate: {
			treatmentId: string
			status?: string
			editorFilestatus?: string
		}) =>
			CelebTreatmentPlanService.getInstance().updateStatus(
				statusUpdate.treatmentId,
				statusUpdate?.status,
				statusUpdate?.editorFilestatus,
			),
		onSuccess: () => {
			queryClient.invalidateQueries()
			return true
		},
	})

export const getPatientByIdQuery = (treatmentId: string | null) => {
	return useSuspenseQuery({
		queryKey: ["PatientData", treatmentId],
		queryFn: () => {
			if (treatmentId === "null") return Promise.resolve(null)
			return CelebTreatmentPlanService.getInstance()
				.getDetails(treatmentId)
				.then((res) => res.data.data)
				.then((res) => res)
		},
	})
}

export const getTPStatusQuery = (treatmentId: string | null) => {
	return useSuspenseQuery({
		queryKey: ["TPStatus", treatmentId],
		queryFn: async () => {
			try {
				if (treatmentId === "null") return Promise.resolve(null)
				const res = await CelebTreatmentPlanService.getInstance().checkTPStatus(treatmentId)
				if (res.data.logs.length == 0) {
					return {
						status: 200,
						message: "Sectioning is completed",
					}
				}
				if (res.data.logs.length > 0) {
					return {
						status: 500,
						message: res.data.logs[0],
					}
				}
				return res.data
			} catch (error: any) {
				if (error.response && error.response.status === 400) {
					console.error("400 Error: Bad Request", error.response.data)
					return {
						status: 204,
						message: "Sectioning is in progress",
					}
				}
			}
		},
	})
}

export const getPatientTransferById = (treatmentId: string | null) => {
	return useSuspenseQuery({
		queryKey: ["PatientTrasnferData", treatmentId],
		queryFn: () => {
			if (treatmentId === "null") return Promise.resolve(null)
			return CelebTreatmentPlanService.getInstance()
				.getTransferedDetails(treatmentId)
				.then((res) => res.data.data)
				.then((res) => res as PatientTransferData)
		},
	})
}

export const getPatientByIdInstrQuery = (treatmentId: string | null) => {
	return useSuspenseQuery({
		queryKey: ["PatientData", treatmentId],
		queryFn: async () => {
			if (treatmentId === null) return Promise.resolve(null)
			try {
				const res = await CelebTreatmentPlanService.getInstance().getDetails(treatmentId)
				return res.data.data
			} catch (error) {
				return Promise.reject(error)
			}
		},
	})
}

export const useTaskCompleteServicesQuery = (props: UseTreatmentServiceQueryProps) =>
	useQuery({
		queryKey: ["treatment", constants.TREATMENT_TASKS, props],
		queryFn: () =>
			CelebTreatmentPlanService.getInstance()
				.getDoctorTreatments(props)
				.then((res) => ({ data: res.data.data, total: res.headers["x-total-count"] }))
				.then(({ data, total }) => {
					return {
						treatment: data,
						total: total,
					}
				}),
	})

export const useGetReplaceOrders = (patientId: string | null | undefined) =>
	useQuery({
		queryKey: [patientId],
		queryFn: () =>
			CelebTreatmentPlanService.getInstance()
				.getReplacementOrders(patientId)
				.then((res) => ({ data: res?.data.data }))
				.then(({ data }) => {
					return {
						orders: data,
					}
				}),
	})

export const useGetReplacementOrderDetail = (patientId: string | null | undefined) =>
	useQuery({
		queryKey: [patientId],
		queryFn: () =>
			CelebTreatmentPlanService.getInstance()
				.getReplacementOrdersInfo(patientId)
				.then((res) => ({ data: res?.data.data }))
				.then(({ data }) => {
					return {
						orders: data as ReplacementInstructions,
					}
				}),
	})

export const useSearchTreatment = (props: UseSearchTreatmentMutationProps) =>
	useQuery({
		queryKey: ["treatment", constants.TREATMENT_TASKS, props],
		queryFn: () =>
			CelebTreatmentPlanService.getInstance()
				.searchTreatments(props)
				.then((res) => ({ data: res.data.data, total: res.headers["x-total-count"] }))
				.then(({ data, total }) => {
					return {
						treatment: data,
						total: total,
					}
				}),
	})

export const PatientEditSchema = z.object({
	id: z.string(),
	treatmentTitle: z.null(),
	instruction: z.string(),
	treatmentStart: z.string(),
	treatmentType: z.null(),
	upperBracketSlotSize: z.string(),
	lowerBracketSlotSize: z.string(),
	teethExtraction: z.object({
		UR1: z.string(),
		UR2: z.string(),
		UR3: z.string(),
		UR4: z.string(),
		UR5: z.string(),
		UR6: z.string(),
		UR7: z.string(),
		UR8: z.string(),
		UL1: z.string(),
		UL2: z.string(),
		UL3: z.string(),
		UL4: z.string(),
		UL5: z.string(),
		UL6: z.string(),
		UL7: z.string(),
		UL8: z.string(),
		LR1: z.string(),
		LR2: z.string(),
		LR3: z.string(),
		LR4: z.string(),
		LR5: z.string(),
		LR6: z.string(),
		LR7: z.string(),
		LR8: z.string(),
		LL1: z.string(),
		LL2: z.string(),
		LL3: z.string(),
		LL4: z.string(),
		LL5: z.string(),
		LL6: z.string(),
		LL7: z.string(),
		LL8: z.string(),
	}),
	teethBonding: z.object({
		UR1: z.string(),
		UR2: z.string(),
		UR3: z.string(),
		UR4: z.string(),
		UR5: z.string(),
		UR6: z.string(),
		UR7: z.string(),
		UR8: z.string(),
		UL1: z.string(),
		UL2: z.string(),
		UL3: z.string(),
		UL4: z.string(),
		UL5: z.string(),
		UL6: z.string(),
		UL7: z.string(),
		UL8: z.string(),
		LR1: z.string(),
		LR2: z.string(),
		LR3: z.string(),
		LR4: z.string(),
		LR5: z.string(),
		LR6: z.string(),
		LR7: z.string(),
		LR8: z.string(),
		LL1: z.string(),
		LL2: z.string(),
		LL3: z.string(),
		LL4: z.string(),
		LL5: z.string(),
		LL6: z.string(),
		LL7: z.string(),
		LL8: z.string(),
	}),
	status: z.string(),
	isReplacementOrder: z.boolean(),
	patientId: z.string(),
	doctorsWithAccess: z.array(z.string()),
	doctorId: z.string(),
	serviceId: z.string(),
	treatmentPlanDoctorAddressId: z.string(),
	createdAt: z.string(),
	updatedAt: z.string(),
	doctor: z.object({
		firstName: z.string(),
		lastName: z.string(),
		id: z.string(),
	}),
	treatmentPlanDoctorAddress: z.object({
		name: z.string(),
		addressLine1: z.string(),
		addressLine2: z.string(),
		city: z.string(),
		state: z.string(),
		pincode: z.string(),
		id: z.string(),
	}),
	editorFilesStatus: z.string(),
	patient: z.object({
		firstName: z.string(),
		lastName: z.string(),
		entityId: z.number(),
		id: z.string(),
		dob: z.string(),
		gender: z.string(),
		ethenicity: z.null(),
		email: z.null(),
		phone: z.null(),
	}),
	fileTreatmentPlanId: z.object({
		id: z.string(),
		fileUri: z.string(),
	}),
})

export type PatientEditSchema = z.infer<typeof PatientEditSchema>

export type UseStatusUpdateQueryProps = {
	status?: number
	message?: string
}

export interface SearchTreatmentType {
	editorFilesStatus: string
	status: string
	id: string
	patient: Patient
	tpProviderCategoryId: TpProviderCategoryId
	tpConsumerCategoryId: TpConsumerCategoryId
	treatmentPlanDoctorAddress: TreatmentPlanDoctorAddress
	doctor: Doctor
	fileTreatmentPlanId: FileTreatmentPlanId[]
}

export type FileTreatmentPlanId = {
	fileUri: string
}
export interface Patient {
	id: string
	entityId: string
	firstName: string
	lastName: string
	email: string
	phone: string
	dob: string
	gender: string
	ethenicity: string
	createdAt: string
	updatedAt: string
}

export interface TpProviderCategoryId {
	id: string
	name: string
	type: string
	deleted: boolean
	deletedBy: any
	deletedOn: any
	serviceId: string
	createdAt: string
	updatedAt: string
}

export interface TpConsumerCategoryId {
	id: string
	name: string
	type: string
	deleted: boolean
	deletedBy: any
	deletedOn: any
	serviceId: string
	createdAt: string
	updatedAt: string
}

export interface TreatmentPlanDoctorAddress {
	id: string
	name: string
	addressLine1: string
	addressLine2: string
	city: string
	state: string
	country: string
	pincode: string
}

export interface Doctor {
	id: string
	firstName: string
	lastName: string
}

export interface TeethExtraction {
	UR1: string
	UR2: string
	UR3: string
	UR4: string
	UR5: string
	UR6: string
	UR7: string
	UR8: string
	UL1: string
	UL2: string
	UL3: string
	UL4: string
	UL5: string
	UL6: string
	UL7: string
	UL8: string
	LR1: string
	LR2: string
	LR3: string
	LR4: string
	LR5: string
	LR6: string
	LR7: string
	LR8: string
	LL1: string
	LL2: string
	LL3: string
	LL4: string
	LL5: string
	LL6: string
	LL7: string
	LL8: string
}

export interface TeethBonding {
	UR1: string
	UR2: string
	UR3: string
	UR4: string
	UR5: string
	UR6: string
	UR7: string
	UR8: string
	UL1: string
	UL2: string
	UL3: string
	UL4: string
	UL5: string
	UL6: string
	UL7: string
	UL8: string
	LR1: string
	LR2: string
	LR3: string
	LR4: string
	LR5: string
	LR6: string
	LR7: string
	LR8: string
	LL1: string
	LL2: string
	LL3: string
	LL4: string
	LL5: string
	LL6: string
	LL7: string
	LL8: string
}

export interface Patient {
	entityId: string
}

export interface TpProviderCategoryId {
	id: string
	name: string
	type: string
	deleted: boolean
	deletedBy: any
	deletedOn: any
	serviceId: string
	createdAt: string
	updatedAt: string
}

export interface TpConsumerCategoryId {
	id: string
	name: string
	type: string
	deleted: boolean
	deletedBy: any
	deletedOn: any
	serviceId: string
	createdAt: string
	updatedAt: string
}

export interface ReplacementInstructions {
	id: string
	entityId: number
	braces: TeethState
	note: string
	"replacement-number": number
	upperWires: string[]
	lowerWires: string[]
	treatmentId: string
	createdAt: string
	updatedAt: string
}
