import { create } from "zustand"
import { AddressSchemaType } from "../services/CelebDoctorAddress.Service"
import { PatientSchemaType } from "../services/CelebPatient.Service"
import { TreatmentSchemaType } from "../services/CelebTreatmentTask.Service"

type EditId = string | null

export interface PatientDialogState {
	modalOpen: boolean
	setModalOpen: (next: boolean) => void

	isPatientDatainProcess: boolean
	setIsPatientDatainProcess: (next: boolean) => void

	editOpen: PatientSchemaType | null
	setEditOpen: (next: PatientSchemaType | null) => void

	transferDialogOpen: TreatmentSchemaType | null
	setTransferDialogOpen: (next: TreatmentSchemaType | null) => void

	checkTpStatusForTreatment: string | null
	setCheckTpStatusForTreatment: (next: string | null) => void

	transferPatientId: EditId
	setTransferPatientId: (next: EditId) => void

	createOpen: boolean
	setCreateOpen: (next: boolean) => void

	typesEditOpen: EditId
	setTypesEditOpen: (next: EditId) => void

	replaceOpen: { id: string; isReplacementNew?: boolean }
	setReplaceOpen: (next: { id: string; isReplacementNew?: boolean }) => void

	paymentCreateOpen: boolean
	setPaymentCreateOpen: (next: boolean) => void

	activeTabSession: number | string
	setActiveTabSession: (next: number | string) => void

	closeModalDialog: boolean
	setCloseModalDialog: (next: boolean) => void

	extractionSelection: boolean
	setExtractionSelection: (next: boolean) => void

	xRays: {
		patient_xray_teeth: fileUploadingType
		patient_xray_skull: fileUploadingType
	}
	setXRays: (property: keyof PatientDialogState["xRays"], next: fileUploadingType) => void

	cbctDocumentJson: {
		patient_cbct: fileUploadingType
	}
	setCbctDocument: (
		property: keyof PatientDialogState["cbctDocumentJson"],
		next: fileUploadingType,
	) => void

	individualPhoto: {
		patient_photo_face_side: fileUploadingType
		patient_photo_face_front: fileUploadingType
		patient_photo_face_front_teeth: fileUploadingType
		patient_photo_jaw_upper: fileUploadingType
		patient_photo_jaw_lower: fileUploadingType
		patient_photo_jaw_right: fileUploadingType
		patient_photo_jaw_front: fileUploadingType
		patient_photo_jaw_left: fileUploadingType
		[key: string]: fileUploadingType
	}

	replaceOrderPhoto: {
		[key: string]: fileUploadingType
	}

	patientCompositePhotoJson: {
		patient_photo_composite: fileUploadingType
	}

	setPatientReplaceOrderPhoto: (
		property: keyof PatientDialogState["replaceOrderPhoto"],
		next: fileUploadingType,
	) => void

	setBulkReplaceOrderPhoto: (next: { [key: string]: fileUploadingType }) => void

	setPatientCompositePhotoJson: (
		property: keyof PatientDialogState["patientCompositePhotoJson"],
		next: fileUploadingType,
	) => void
	setIndividualPhoto: (
		property: keyof PatientDialogState["individualPhoto"],
		next: fileUploadingType,
	) => void

	isUploadFromScanner: {
		selectedScanner: String | null
	}
	setIsUploadFromScanner: (selectedScanner: string | null) => void

	scan: {
		patient_scan_upper: fileUploadingType
		patient_scan_lower: fileUploadingType
		[key: string]: fileUploadingType
	}
	setScan: (property: keyof PatientDialogState["scan"], next: fileUploadingType) => void

	navErrors: {
		infoTab: "red" | "none"
		scanTab: "red" | "none"
		instructions: "red" | "none"
		photos: "red" | "none"
		cbct: "red" | "none"
		replace: "red" | "none"
	}
	setNavErrors: (next: keyof PatientDialogState["navErrors"], value: "red" | "none") => void

	errorToShow: {
		createPatient: string
	}
	setErrorToShow: (message: string) => void

	clearState: () => void

	allOffices: AddressSchemaType[]
	setAllOffices: (next: AddressSchemaType[]) => void
	doctorList: { value: any; label: string }[]
	setDoctorList: (next: { value: any; label: string }[]) => void

	customErrors: {
		email: string
	}

	profilePicture: fileUploadingType
	setProfilePicture: (next: fileUploadingType) => void

	setCustomError: (key: string, message: string) => void
}

export const usePatientDialogStore = create<PatientDialogState>()((set) => ({
	modalOpen: false,
	setModalOpen: (next: boolean) => set({ modalOpen: next }),
	isPatientDatainProcess: false,
	setIsPatientDatainProcess: (next: boolean) => set({ isPatientDatainProcess: next }),
	editOpen: null,
	setEditOpen: (next: PatientSchemaType | null) => set({ editOpen: next }),
	transferDialogOpen: null,
	setTransferDialogOpen: (next: TreatmentSchemaType | null) => set({ transferDialogOpen: next }),
	checkTpStatusForTreatment: null,
	setCheckTpStatusForTreatment: (next: string | null) => set({ checkTpStatusForTreatment: next }),
	createOpen: false,
	setCreateOpen: (next: boolean) => set({ createOpen: next }),
	typesEditOpen: null,
	setTypesEditOpen: (next: EditId) => set({ typesEditOpen: next }),
	transferPatientId: null,
	setTransferPatientId: (next: EditId) => set({ transferPatientId: next }),
	replaceOpen: { id: "", isReplacementNew: false },
	setReplaceOpen: (next) => set({ replaceOpen: next }),
	paymentCreateOpen: false,
	setPaymentCreateOpen: (next: boolean) => set({ paymentCreateOpen: next }),
	activeTabSession: 0,
	closeModalDialog: false,
	setCloseModalDialog: (next: boolean) => set({ closeModalDialog: next }),
	setActiveTabSession: (next) => set({ activeTabSession: next }),
	individualPhoto: {
		patient_photo_face_side: {
			additionalInfo: {},
			file: null,
		},
		patient_photo_face_front: {
			additionalInfo: {},
			file: null,
		},
		patient_photo_face_front_teeth: {
			additionalInfo: {},
			file: null,
		},
		patient_photo_jaw_upper: {
			additionalInfo: {},
			file: null,
		},
		patient_photo_jaw_lower: {
			additionalInfo: {},
			file: null,
		},
		patient_photo_jaw_right: {
			additionalInfo: {},
			file: null,
		},
		patient_photo_jaw_front: {
			additionalInfo: {},
			file: null,
		},
		patient_photo_jaw_left: {
			additionalInfo: {},
			file: null,
		},
	},

	extractionSelection: false,
	setExtractionSelection: (next: boolean) => set({ extractionSelection: next }),

	xRays: {
		patient_xray_skull: {
			additionalInfo: {},
			file: null,
		},
		patient_xray_teeth: {
			additionalInfo: {},
			file: null,
		},
	},

	setXRays: (property: keyof PatientDialogState["xRays"], next: fileUploadingType) =>
		set((state) => ({
			xRays: {
				...state.xRays,
				[property]: next,
			},
		})),

	setIndividualPhoto: (
		property: keyof PatientDialogState["individualPhoto"],
		next: fileUploadingType,
	) =>
		set((state) => ({
			individualPhoto: {
				...state.individualPhoto,
				[property]: next,
			},
		})),

	cbctDocumentJson: {
		patient_cbct: {
			additionalInfo: {},
			file: null,
		},
	},
	setCbctDocument: (
		property: keyof PatientDialogState["cbctDocumentJson"],
		next: fileUploadingType,
	) =>
		set((state) => {
			return {
				cbctDocumentJson: {
					...state.cbctDocumentJson,
					[property]: next,
				},
			}
		}),

	patientCompositePhotoJson: {
		patient_photo_composite: {
			additionalInfo: {},
			file: null,
		},
	},

	setPatientCompositePhotoJson: (
		property: keyof PatientDialogState["patientCompositePhotoJson"],
		next: fileUploadingType,
	) =>
		set((state) => ({
			patientCompositePhotoJson: {
				...state.patientCompositePhotoJson,
				[property]: next,
			},
		})),

	isUploadFromScanner: {
		selectedScanner: null,
	},

	setIsUploadFromScanner: (selectedScanner: string | null) =>
		set(() => ({
			isUploadFromScanner: {
				selectedScanner,
			},
		})),

	scan: {
		patient_scan_upper: {
			additionalInfo: {},
			file: null,
		},
		patient_scan_lower: {
			additionalInfo: {},
			file: null,
		},
	},
	setScan: (property: keyof PatientDialogState["scan"], next: fileUploadingType) =>
		set((state) => ({
			scan: {
				...state.scan,
				[property]: next,
			},
		})),
	replaceOrderPhoto: {
		replace_order_photo_0: {
			additionalInfo: {},
			file: null,
		},
	},
	setPatientReplaceOrderPhoto: (
		property: keyof PatientDialogState["replaceOrderPhoto"],
		next: fileUploadingType,
	) =>
		set((state) => ({
			replaceOrderPhoto: {
				...state.replaceOrderPhoto,
				[property]: next,
			},
		})),

	setBulkReplaceOrderPhoto: (next: { [key: string]: fileUploadingType }) => {
		set((state) => (state.replaceOrderPhoto = next))
	},

	navErrors: {
		infoTab: "none",
		scanTab: "none",
		instructions: "none",
		photos: "none",
		cbct: "none",
		replace: "none",
	},
	setNavErrors: (next: keyof PatientDialogState["navErrors"], value: "red" | "none") =>
		set((state) => ({
			navErrors: {
				...state.navErrors,
				[next]: value,
			},
		})),
	errorToShow: {
		createPatient: "",
	},
	setErrorToShow: (message: string) =>
		set((state) => ({
			errorToShow: {
				...state.errorToShow,
				createPatient: message,
			},
		})),

	clearState: () => {
		set({
			profilePicture: undefined,
			modalOpen: false,
			editOpen: null,
			transferDialogOpen: null,
			transferPatientId: null,
			createOpen: false,
			closeModalDialog: false,
			typesEditOpen: null,
			replaceOpen: { id: "", isReplacementNew: false },
			paymentCreateOpen: false,
			activeTabSession: 0,
			extractionSelection: false,
			isPatientDatainProcess: false,
			isUploadFromScanner: {
				selectedScanner: null,
			},
			individualPhoto: {
				patient_photo_face_side: {
					additionalInfo: {},
					file: null,
				},
				patient_photo_face_front: {
					additionalInfo: {},
					file: null,
				},
				patient_photo_face_front_teeth: {
					additionalInfo: {},
					file: null,
				},
				patient_photo_jaw_upper: {
					additionalInfo: {},
					file: null,
				},
				patient_photo_jaw_lower: {
					additionalInfo: {},
					file: null,
				},
				patient_photo_jaw_right: {
					additionalInfo: {},
					file: null,
				},
				patient_photo_jaw_front: {
					additionalInfo: {},
					file: null,
				},
				patient_photo_jaw_left: {
					additionalInfo: {},
					file: null,
				},
			},
			xRays: {
				patient_xray_skull: {
					additionalInfo: {},
					file: null,
				},
				patient_xray_teeth: {
					additionalInfo: {},
					file: null,
				},
			},
			cbctDocumentJson: {
				patient_cbct: {
					additionalInfo: {},
					file: null,
				},
			},
			patientCompositePhotoJson: {
				patient_photo_composite: {
					additionalInfo: {},
					file: null,
				},
			},
			scan: {
				patient_scan_upper: {
					additionalInfo: {},
					file: null,
				},
				patient_scan_lower: {
					additionalInfo: {},
					file: null,
				},
			},
			replaceOrderPhoto: {
				replace_order_photo_0: {
					additionalInfo: {},
					file: null,
				},
			},
			navErrors: {
				infoTab: "none",
				scanTab: "none",
				instructions: "none",
				photos: "none",
				cbct: "none",
				replace: "none",
			},
			allOffices: [],
			customErrors: {
				email: "",
			},
		})
		localStorage.removeItem("patient")
	},
	allOffices: [],
	setAllOffices: (next: AddressSchemaType[]) =>
		set(() => ({
			allOffices: next,
		})),
	doctorList: [],
	setDoctorList: (next: { value: any; label: string }[]) =>
		set(() => ({
			doctorList: next,
		})),
	customErrors: {
		email: "",
	},
	profilePicture: {
		additionalInfo: {},
		file: null,
	},
	setProfilePicture: (next: fileUploadingType) =>
		set(() => ({
			profilePicture: next,
		})),

	setCustomError: (key, message) =>
		set((state) => ({
			customErrors: { ...state.customErrors, [key]: message },
		})),
}))

export type fileUploadingType = {
	file: File | null
	additionalInfo: {
		note?: string
	}
	deleted?: boolean | null
}
