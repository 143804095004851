export const constants = {
	BASE_URL: "/api",
	// BASE_URL: 'http://3.22.222.82',
	// BASE_URL: "https://test.celebrace.com/api",
	IMAGEURL: process.env.REACT_APP_S3BASE!,
	API_V1: "/api/v1",
	USER_LOGIN: `/auth/login`,
	FORGOT_PASSWORD: `/auth/forgotpassword`,
	RESET_PASSWORD: `/auth/reset/:id`,
	VALIDATE_LINK: `/auth/validate/`,
	SERVICE: `/services`,
	ROLES: "/roles",
	USERS: "/users",
	DOCTOR: "/doctor",
	GENERIC_PERMISSIONS: "/genericpermissions",
	DOCTOR_ADDRESS: "/doctoraddresses",
	USER_SERVICE_PRICINGS: "/userservicepricing",
	MEDICAL_FILES: "/medicalfiles",
	MEDICAL_FILES_UPLOAD_URLS: "/medicalfiles/uploadurls",
	PATIENTS: "/patients",
	TREATMENT: "/treatment",
	TREATMENT_TASKS: "/treatmenttasks",
	TREATMENT_PLAN: "/treatmentplans",
	TREATMENT_PRICING: "/treatmentpricing",
	TRANSFERED: "/transfered",
	TREATMENT_FILES: "/treatmentfiles",
	COUNT: "/count",
	PAYMENTS: "/payments",
	PREFERRED_PAYMENTS: "/preferred",
	FETCH_PREFERRED_PAYMENTS: "/getpreferred",
	PAGE_COUNT: 10,
	SERVICE_TASKS: "/servicetasks",
	SALES_CLIENT: "/salesclient",
	TASK_CATEGORIES: "/taskcategories",
	FORGOT_PASSWORD_V1: `/forgotpassword/forgotpassword`,
	RESET_PASSWORD_V1: `/forgotpassword/resetpassword`,
	VALIDATE_PASSWORD_V1: `/forgotpassword/validatetoken`,
	INVOICE: `/invoice`,
}
