import { CelebMedicalFileService } from "../../services/CelebMedicalFile.Service"
import { TreatmentFileSubCategory } from "../../utils/appConstants"

export const fileImageSaver = async (
	e: React.ChangeEvent<HTMLInputElement>,
	property: TreatmentFileSubCategory,
) => {
	e.preventDefault()
	if (e.currentTarget.files) {
		const filesObject = {
			[property]: e.currentTarget.files[0],
		}
		try {
			const uploadedFiles = await uploadFiles(filesObject, "patient_scan")
			const treatmentFiles = uploadedFiles.filter(
				(file): file is UploadData => file !== undefined && file.uri.length >= 1,
			)
		} catch (error) {
			console.error("File upload failed:", error)
		}
	}
}

type UploadData = {
	category: string
	subCategory: string
	originalFileName: string
	uri: string
}

export const uploadFiles = async (files: { [key: string]: File | null }, category: string) => {
	const uploadPromises = Object.keys(files).map(async (fileKey) => {
		const file = files[fileKey]
		if (file) {
			return await uploadMedicalFiles(file, category, fileKey)
		}
		return undefined
	})
	// console.log( "uploadPromises", uploadPromises )

	return await Promise.all(uploadPromises)
}

const uploadMedicalFiles = async (file: File, category: string, subCategory: string) => {
	const medicalFileService = CelebMedicalFileService.getInstance()
	const fileUploadResponse = await medicalFileService.getUploadUrls([file?.name])
	const uploadUrl = fileUploadResponse.data.data[0].uploadUrl
	const response = await medicalFileService.uploadFileToS3(uploadUrl, file)
	if (response.status === 200) {
		const fileName = uploadUrl.split("?")[0].split("/")[3]
		const dataReturn = {
			category: category,
			subCategory: subCategory,
			originalFileName: file?.name,
			uri: fileName,
		}
		return dataReturn
	}
}

export const uploadLogo = async (serviceLogo: FileList | null) => {
	console.log(serviceLogo)
	if (serviceLogo && serviceLogo?.length >= 1) {
		const medicalFileService = CelebMedicalFileService.getInstance()
		const fileUploadResponse = await medicalFileService.getPublicUploadUrls([serviceLogo[0]?.name])
		const uploadUrl = fileUploadResponse.data.data[0].uploadUrl
		const response = await medicalFileService.uploadFileToS3(uploadUrl, serviceLogo[0])
		console.log("hi", uploadUrl.split("?")[0].split("/")[3])
		if (response.status === 200) {
			console.log("hi")
			const logo = uploadUrl.split("?")[0].split("/")[3]
			return logo
		}
	}
}
